import React, { FC } from 'react';
// components
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
// types
import { FilterFooterProps } from './types';

const FilterFooter: FC<FilterFooterProps> = ({ disableApply, onOk, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Space>
            <Button
              onClick={onCancel}
              className="extraFooter-cancel--btn"
            >
                {t('Cancel')}
            </Button>
            <Button
              onClick={onOk}
              disabled={disableApply}
              className="extraFooter-apply--btn"
            >
                {t('Apply')}
            </Button>
        </Space>
    );
};

export default FilterFooter;
