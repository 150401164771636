import React from 'react';

import './Wrapper.scss';

// eslint-disable-next-line no-undef
function Wrapper({ children }: { children: JSX.Element }) {
    return (
        <div className="custom-wrapper">
            { children }
        </div>
    );
}

export default Wrapper;
