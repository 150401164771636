import React, { forwardRef, LegacyRef } from 'react';

const GithubLogotype = forwardRef((props: { color: string }, ref: LegacyRef<SVGSVGElement>) => {
    const { color } = props;
    return (
        <svg
          ref={ref}
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="3840.000000pt"
          height="2160.000000pt"
          viewBox="0 0 3840.000000 2160.000000"
          preserveAspectRatio="xMidYMid meet"
          // eslint-disable-next-line
        >

            <g
              transform="translate(0.000000,2160.000000) scale(0.100000,-0.100000)"
              fill={color}
              stroke="none"
            >
                <path d="M5095 15734 c-206 -19 -365 -38 -495 -60 -1040 -172 -1989 -658
-2741 -1404 -881 -874 -1401 -2012 -1489 -3258 -44 -610 32 -1250 220 -1862
94 -305 273 -711 445 -1010 505 -878 1273 -1593 2180 -2031 194 -94 360 -164
520 -219 107 -37 128 -41 191 -37 124 7 207 67 235 170 7 26 10 207 8 529 l-2
490 -36 -6 c-227 -41 -316 -49 -506 -49 -215 -1 -307 11 -471 59 -180 52 -353
153 -483 281 -101 101 -151 177 -227 344 -165 366 -304 552 -524 699 -199 133
-262 225 -194 279 59 46 203 58 334 27 96 -23 257 -100 340 -164 110 -83 219
-207 324 -367 147 -222 318 -382 471 -441 54 -20 57 -29 10 -30 -96 -2 -98
-101 -2 -139 106 -42 182 35 104 107 -30 29 -30 30 -7 23 89 -28 139 -37 251
-42 185 -8 367 23 549 95 l75 29 13 59 c60 267 140 438 265 572 28 28 44 52
36 52 -8 0 -75 9 -149 20 -422 60 -739 153 -1035 303 -647 329 -1013 926
-1094 1787 -49 519 3 911 170 1260 75 157 162 290 305 467 l25 31 -24 79 c-86
269 -97 580 -32 898 27 134 97 353 117 367 7 5 72 7 143 4 100 -3 157 -11 245
-34 268 -69 637 -246 942 -454 l58 -39 52 16 c203 58 581 121 859 142 186 14
669 8 839 -11 262 -28 566 -83 736 -131 l52 -15 138 88 c430 274 804 424 1095
439 170 9 159 15 207 -129 127 -383 142 -777 45 -1108 -14 -47 -27 -92 -30
-100 -2 -9 24 -50 62 -95 182 -218 321 -490 390 -763 107 -425 72 -1078 -84
-1552 -237 -718 -735 -1155 -1546 -1358 -160 -40 -402 -85 -525 -97 -99 -10
-99 -10 -53 -58 160 -166 265 -427 289 -712 3 -44 7 -436 8 -871 l1 -791 30
-49 c19 -30 49 -60 79 -79 46 -28 56 -30 145 -29 92 0 102 3 241 56 952 366
1722 943 2312 1735 185 248 337 497 484 795 290 589 460 1226 505 1898 21 322
3 762 -46 1090 -149 1005 -608 1951 -1313 2706 -687 737 -1583 1252 -2571
1479 -384 88 -640 117 -1081 120 -190 1 -363 1 -385 -1z"
                />
                <path d="M14671 14295 c-718 -67 -1255 -292 -1667 -700 -596 -591 -893 -1540
-896 -2865 -1 -646 66 -1153 214 -1610 253 -785 726 -1316 1421 -1594 416
-167 853 -241 1442 -245 368 -2 580 17 889 80 291 59 617 170 861 291 104 52
245 140 255 158 6 12 10 634 10 1722 0 1598 -2 1704 -18 1724 -9 12 -27 27
-39 33 -16 8 -336 11 -1138 11 l-1117 0 -34 -34 -34 -34 0 -564 c0 -536 1
-565 19 -594 11 -17 32 -35 48 -40 15 -5 220 -9 456 -9 l427 1 -2 -665 -3
-665 -30 -8 c-188 -50 -761 -71 -980 -34 -430 71 -735 297 -932 690 -165 328
-246 739 -259 1311 -24 982 181 1634 624 1987 207 165 484 265 821 296 102 9
569 9 706 -1 245 -17 516 -69 748 -142 129 -41 157 -43 192 -12 23 20 36 67
130 467 58 245 116 492 130 548 35 147 31 154 -117 226 -264 129 -672 223
-1133 261 -44 4 -93 9 -110 11 -86 12 -750 11 -884 -1z"
                />
                <path d="M23626 14045 c-20 -6 -37 -22 -48 -45 -17 -33 -18 -210 -18 -3263 0
-3487 -3 -3273 51 -3311 21 -14 88 -16 662 -16 683 0 680 0 707 49 7 13 10
477 10 1415 l0 1396 961 0 960 0 -5 -1382 c-4 -1133 -2 -1388 9 -1414 28 -67
-9 -64 706 -64 636 0 647 0 672 20 15 12 31 38 37 58 14 52 14 6442 -1 6495
-21 75 13 72 -688 72 -344 1 -639 -2 -656 -7 -16 -4 -38 -17 -50 -28 -20 -20
-20 -30 -25 -1233 l-5 -1212 -955 0 -955 0 -5 1213 -5 1214 -28 24 c-15 13
-36 25 -45 25 -99 7 -1262 2 -1286 -6z"
                />
                <path d="M33818 14052 c-14 -2 -38 -15 -52 -29 l-26 -26 0 -3264 0 -3265 29
-29 29 -29 460 0 460 0 31 26 c17 14 35 42 40 62 8 32 41 236 41 256 0 4 19
-8 43 -27 67 -55 204 -141 307 -192 348 -175 736 -258 1160 -250 515 10 865
136 1136 409 284 286 456 729 529 1356 93 808 39 1534 -152 2034 -46 121 -141
302 -211 401 -111 156 -290 314 -462 408 -333 183 -850 236 -1333 136 -210
-43 -461 -133 -609 -219 l-68 -39 0 1100 c0 1192 2 1140 -53 1171 -17 9 -170
12 -647 13 -344 0 -637 -1 -652 -3z m2287 -3167 c293 -79 457 -333 510 -790
10 -83 15 -222 15 -385 0 -830 -122 -1149 -472 -1235 -89 -22 -328 -28 -455
-10 -184 25 -408 98 -520 168 l-43 27 0 1020 0 1020 72 36 c154 77 360 143
515 163 99 13 307 5 378 -14z"
                />
                <path d="M18508 13900 c-386 -65 -665 -391 -669 -781 -2 -154 31 -281 106
-414 192 -340 624 -488 983 -337 233 98 407 305 468 558 24 95 23 277 0 369
-23 90 -86 225 -135 289 -179 237 -478 362 -753 316z"
                />
                <path d="M20584 13232 c-56 -8 -54 16 -56 -682 l-3 -644 -342 -86 c-188 -47
-351 -92 -363 -100 -11 -8 -24 -31 -29 -50 -4 -19 -7 -218 -6 -441 0 -372 2
-408 18 -433 10 -15 29 -31 43 -36 14 -6 170 -10 355 -10 l329 0 2 -1032 c1
-568 2 -1040 3 -1048 1 -8 5 -51 9 -95 19 -197 77 -417 152 -571 163 -335 460
-558 869 -652 185 -43 317 -56 575 -56 223 0 259 2 400 27 196 34 380 82 403
105 9 10 21 31 25 47 13 50 15 890 2 929 -23 70 -54 76 -285 57 -356 -29 -601
55 -693 235 -54 105 -52 72 -52 1101 l0 953 476 0 476 0 34 34 34 34 0 520 c0
314 -4 531 -10 546 -5 14 -24 35 -42 46 -32 19 -51 20 -500 20 l-468 0 -1 268
c-2 888 -4 981 -22 999 -15 15 -72 17 -664 17 -357 1 -658 0 -669 -2z"
                />
                <path d="M17975 11940 c-11 -4 -31 -20 -45 -35 l-25 -27 -3 -2176 c-2 -2057
-1 -2179 15 -2212 12 -23 34 -43 63 -58 l45 -22 612 2 c603 3 612 3 640 24 15
11 33 36 40 55 15 44 19 4353 3 4393 -5 14 -24 35 -42 46 -32 19 -50 20 -658
19 -344 0 -634 -4 -645 -9z"
                />
                <path d="M29013 11933 c-12 -2 -34 -18 -48 -35 l-25 -30 1 -1601 c1 -1706 1
-1690 50 -1922 107 -504 379 -816 840 -963 356 -114 886 -135 1292 -51 275 57
639 199 877 342 88 53 81 60 105 -98 14 -97 20 -114 46 -140 l29 -30 328 2
c589 2 529 -1 563 32 l29 29 0 2201 0 2201 -29 32 -29 33 -631 3 c-704 3 -689
4 -716 -62 -13 -31 -15 -251 -15 -1620 l0 -1585 -72 -42 c-138 -80 -329 -145
-506 -170 -49 -7 -138 -9 -215 -6 -286 11 -413 98 -483 332 -17 56 -19 150
-24 1570 -4 1231 -7 1514 -18 1533 -30 52 -33 52 -704 51 -343 -1 -633 -4
-645 -6z"
                />
                <path d="M2131 8586 c-34 -40 46 -108 102 -87 54 21 21 88 -49 98 -29 4 -43 1
-53 -11z"
                />
                <path d="M2380 8410 c-32 -60 77 -162 124 -115 43 44 -11 134 -81 135 -22 0
-36 -6 -43 -20z"
                />
                <path d="M2554 8155 c-45 -69 68 -193 126 -140 34 31 22 96 -26 140 -36 33
-78 33 -100 0z" />
                <path d="M2823 7835 c-29 -21 -33 -47 -13 -92 29 -64 100 -98 145 -69 53 35
16 130 -62 162 -40 17 -44 17 -70 -1z"
                />
                <path d="M3655 7593 c-55 -10 -67 -15 -85 -33 -57 -57 31 -135 122 -109 37 10
78 46 78 68 -1 38 -70 82 -115 74z"
                />
                <path d="M4070 7590 c-100 -18 -133 -97 -58 -136 56 -29 157 4 171 55 13 50
-48 93 -113 81z"
                />
            </g>
        </svg>
    );
});

export default GithubLogotype;
