import React, { forwardRef } from 'react';

interface CloseProps {
    light: boolean;
}

const Close = forwardRef<SVGSVGElement, CloseProps>((props, ref) => (
    <svg ref={ref} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" fill={props.light ? '#F6F7F9' : '#444B5F'} />
        <path d="M9.16992 14.83L14.8299 9.17" stroke={props.light ? '#444B5F' : '#F6F7F9'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.8299 14.83L9.16992 9.17" stroke={props.light ? '#444B5F' : '#F6F7F9'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
));

export default Close;
