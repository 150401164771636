import React from 'react';
// components
import { useTranslation } from 'react-i18next';
import Icon from '../../components/Icon';
// utils
// assets
import TagIcon from '../../assets/images/svg/tag.svg';
import RefreshIcon from '../../assets/images/svg/refresh.svg';
import SecurityIcon from '../../assets/images/svg/security.svg';
import './About.scss';

function About() {
    const { t } = useTranslation();

    return (
        <>
            <section className="about-us-page services-page--header">
                <h2>{t('about_page__about_us')}</h2>
            </section>
            <section className="services-page--wrapper about-page--wrapper">
                <h5>{t('about_page__secure_your_data_from_ransomware')}</h5>
                <p className="max-width">
                    {t('about_page__small_businesses_make_up_over')}
                    {' '}
                    <a target="_blank" rel="noreferrer" href="https://www.forbes.com/sites/tedknutson/2021/07/27/small-businesses-bearing-brunt-of-ransomware-attacks-senate-told/?sh=234876d49556">{t('about_page__fifty_percent_of_ransomware_victims')}</a>
                    .
                    {' '}
                    {t('about_page__protect_your_business')}
                </p>

                <h5 className="mt-20">{t('about_page__ransomproof_promises')}</h5>
                <ul className="mt-20">
                    <li>
                        <div>
                            <Icon url={TagIcon} width={32} />
                        </div>
                        {t('about_page__free_forever_cancel_anytime')}
                    </li>
                    <li>
                        <div className="refresh">
                            <Icon url={RefreshIcon} width={32} />
                        </div>
                        {t('about_page__automatic_backup_once_per_day')}
                    </li>
                    <li>
                        <div className="security">
                            <Icon url={SecurityIcon} width={32} />
                        </div>
                        {t('about_page__your_data_is_secure')}
                    </li>
                </ul>

                <p className="mt-20 max-width">
                    {t('about_page__large_text')}
                </p>

                <div className="versioning-wrapper">
                    <span>{t('Version')}: 01.1.0.0422 / {t('Release')}: Beta</span>
                </div>
            </section>
        </>
    );
}

export default About;
