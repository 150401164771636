import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Notification from '../Notification';
import Button from '../Button';
// utils
import { authenticationService } from '../../services/authentication';
import EmailIcon from '../../assets/images/svg/sms.svg';
import Icon from '../Icon';
import BackIcon from '../../assets/images/svg/arrow-left.svg';

interface ICodeDeliveryDetails {
    AttributeName: string,
    DeliveryMedium: string,
    Destination: string,
}

interface IProps {
    onChange: Function,
}

function EnterEmail({ onChange }: IProps) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const onFinish = async (values: any) => {
        const { email } = values;
        setIsLoading(true);
        try {
            const response: any = await authenticationService.resendConfirmCode(email);
            const { status } = response;
            if (status === 'failed') throw Error(response.message);

            const { CodeDeliveryDetails }: {
                CodeDeliveryDetails: ICodeDeliveryDetails
            } = response;
            Notification({
                type: 'success',
                message: t('Success_message'),
                description: `${t('auth_page__sent_an_email')}: ${CodeDeliveryDetails.Destination} ${t('auth_page__with_secret_code')}`,
                duration: 2,
            });
            onChange(email);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: 2,
            });
            setIsLoading(false);
        }
    };

    return (
        <div className="login_form--container">
            <Button
              type="link"
              size="small"
              onClick={() => history.push('/login')}
              className="go-back--btn"
                    // eslint-disable-next-line
                >
                <Icon url={BackIcon} />
                {t('Back')}
            </Button>
            <h2>{t('auth_page__signup_confirmation')}</h2>
            <p>{t('auth_page__enter_your_email_address_below')}</p>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              className="login-form"
                    // eslint-disable-next-line
                >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: t('auth_page__please_input_your_email') }]}
                  className="custom-input custom-input--text"
                >
                    <Input prefix={<Icon url={EmailIcon} />} placeholder={t('auth_page__enter_your_email')} />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        {t('auth_page__confirm_signup')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default EnterEmail;
