import React, {
    useState, useMemo, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { Modal as AntModal, Switch, Tooltip } from 'antd';
import { useSwipeable } from 'react-swipeable';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
// types
import { IIcon } from '../../Service';
// utils
import { formatDMY } from '../utils';
// assets
import HistoryIcon from '../../../assets/images/svg/clock.svg';
import DownloadIcon from '../../../assets/images/svg/download-2.svg';
import DeleteIcon from '../../../assets/images/svg/trash-2.svg';
import ShopifyIcon from '../../../assets/images/svg/shopify.svg';
import SalesforceIcon from '../../../assets/images/svg/salesforce.svg';
import QuickbooksIcon from '../../../assets/images/svg/quickbooks.svg';
import SlackIcon from '../../../assets/images/svg/slack.svg';
import GithubIcon from '../../../assets/images/svg/github.svg';
import HubspotIcon from '../../../assets/images/svg/hubspot.svg';
import TickIcon from '../../../assets/images/svg/tick.svg';
import ErrorIcon from '../../../assets/images/svg/error-2.svg';
import ActionsIcon from '../../../assets/images/svg/actions.svg';
import LineIcon from '../../../assets/images/svg/line.svg';
import './ServiceCard.scss';

interface IProps {
    id: string,
    service: string,
    serviceId: string,
    serviceIdAlias: string,
    lastSyncStatus: string,
    lastSyncDate: string,
    active: boolean,
    loading?: boolean,
    toggleSwitch: Function,
    subscriptionId: string,
    restore: Function,
    remove: Function,
}

function HistoryIconWrapper() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#565D73" />
            <path d="M15.7096 15.93C15.5796 15.93 15.4496 15.9 15.3296 15.82L12.2296 13.97C11.4596 13.51 10.8896 12.5 10.8896 11.61V7.51001C10.8896 7.10001 11.2296 6.76001 11.6396 6.76001C12.0496 6.76001 12.3896 7.10001 12.3896 7.51001V11.61C12.3896 11.97 12.6896 12.5 12.9996 12.68L16.0996 14.53C16.4596 14.74 16.5696 15.2 16.3596 15.56C16.2096 15.8 15.9596 15.93 15.7096 15.93Z" fill="#565D73" />
        </svg>
    );
}

function DownloadIconWrapper() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M16.19 2H7.82C4.17 2 2 4.17 2 7.81V16.18C2 19.82 4.17 21.99 7.81 21.99H16.18C19.82 21.99 21.99 19.82 21.99 16.18V7.81C22 4.17 19.83 2 16.19 2Z" fill="#565D73" />
            <path d="M11.4695 15.04C11.5395 15.11 11.6195 15.16 11.7095 15.2C11.7995 15.24 11.8995 15.26 11.9995 15.26C12.0995 15.26 12.1895 15.24 12.2895 15.2C12.3795 15.16 12.4595 15.11 12.5295 15.04L15.5295 12.04C15.8195 11.75 15.8195 11.27 15.5295 10.98C15.2395 10.69 14.7595 10.69 14.4695 10.98L12.7495 12.7V6.51001C12.7495 6.10001 12.4095 5.76001 11.9995 5.76001C11.5895 5.76001 11.2495 6.10001 11.2495 6.51001V12.7L9.52945 10.98C9.23945 10.69 8.75945 10.69 8.46945 10.98C8.17945 11.27 8.17945 11.75 8.46945 12.04L11.4695 15.04Z" fill="#565D73" />
            <path d="M18.7097 16.28C18.5797 15.89 18.1597 15.68 17.7597 15.81C14.0397 17.05 9.9497 17.05 6.2297 15.81C5.8397 15.68 5.40969 15.89 5.27969 16.28C5.14969 16.67 5.35969 17.1 5.74969 17.23C7.75969 17.9 9.86969 18.24 11.9897 18.24C14.1097 18.24 16.2197 17.9 18.2297 17.23C18.6297 17.09 18.8397 16.67 18.7097 16.28Z" fill="#565D73" />
        </svg>
    );
}

function DeleteIconWrapper() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z" fill="#565D73" />
            <path opacity="0.3991" d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14Z" fill="#565D73" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.58008 17C9.58008 16.5858 9.91586 16.25 10.3301 16.25H13.6601C14.0743 16.25 14.4101 16.5858 14.4101 17C14.4101 17.4142 14.0743 17.75 13.6601 17.75H10.3301C9.91586 17.75 9.58008 17.4142 9.58008 17Z" fill="#565D73" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.75 13C8.75 12.5858 9.08579 12.25 9.5 12.25H14.5C14.9142 12.25 15.25 12.5858 15.25 13C15.25 13.4142 14.9142 13.75 14.5 13.75H9.5C9.08579 13.75 8.75 13.4142 8.75 13Z" fill="#565D73" />
        </svg>
    );
}

function ServiceCard({
    toggleSwitch, restore, remove, ...record
}: IProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const isMobile = window.innerWidth <= 480;

    const [show, setShow] = useState(false);

    const handlers = useSwipeable({
        onSwipedDown: () => setShow(false),
    });

    const {
        service, lastSyncStatus, lastSyncDate, active,
        loading, serviceId, serviceIdAlias, subscriptionId,
    } = record;

    const handleShowActions = useCallback(() => {
        setShow(true);
    }, [setShow]);

    const icons: IIcon = useMemo(() => ({
        shopify: ShopifyIcon,
        quickbooks: QuickbooksIcon,
        salesforce: SalesforceIcon,
        slack: SlackIcon,
        hubspot: HubspotIcon,
        github: GithubIcon,
    }), []);

    const companyName = useMemo(() => {
        if (serviceIdAlias) return serviceIdAlias;
        if (serviceId) return serviceId;
        return undefined;
    }, [serviceId, serviceIdAlias]);

    const display = ['quickbooks', 'shopify', 'salesforce', 'hubspot', 'slack', 'github'].includes(record.service);

    return (
        <div className="service-card">
            <div className="service-name">
                <div>
                    <div className="icon-wrapper">
                        <Icon url={icons[service]} width={32} />
                    </div>
                    <h5>{ service === 'quickbooks' ? 'QuickBooks' : service }</h5>
                </div>
                <div>
                    {
                        isMobile
                            ? (
                                <Tooltip placement="right" title="Actions">
                                    <Button
                                      className="actions-btn download-btn"
                                      onClick={handleShowActions}
                                    >
                                        <Icon url={ActionsIcon} width={24} />
                                    </Button>
                                </Tooltip>
                            )
                            : (
                                <>
                                    <Tooltip trigger={['hover', 'focus']} placement="top" title={t('home_page__audit_of_actions')}>
                                        <Button
                                          style={{ display: display ? 'block' : 'none' }}
                                          className="actions-btn download-btn"
                                          onClick={() => history.push({
                                                pathname: '/audit_of_actions',
                                                state: { id: subscriptionId },
                                            })}
                                        >
                                            <HistoryIconWrapper />
                                            {/* <Icon url={HistoryIcon} width={24} /> */}
                                        </Button>
                                    </Tooltip>
                                    <Tooltip trigger={['hover', 'focus']} placement="top" title={t('home_page__download_backup')}>
                                        <Button
                                          style={{ display: display ? 'block' : 'none' }}
                                          className="actions-btn download-btn"
                                          onClick={() => restore(record)}
                                        >
                                            <DownloadIconWrapper />
                                            {/* <Icon url={DownloadIcon} width={24} /> */}
                                        </Button>
                                    </Tooltip>
                                    {
                                        active
                                        ? (
                                            <Tooltip trigger={['hover', 'focus']} placement="top" title={t('service_card__only_disabled_can_be_deleted')}>
                                                <Button className="actions-btn remove-btn remove-btn-dummy">
                                                    <DeleteIconWrapper />
                                                    {/* <Icon url={DeleteIcon} width={24} /> */}
                                                </Button>
                                            </Tooltip>
                                        )
                                        : (
                                            <Tooltip trigger={['hover', 'focus']} placement="top" title={t('home_page__delete_backup')}>
                                                <Button
                                                  disabled={active}
                                                  className="actions-btn remove-btn"
                                                  onClick={() => remove(record)}
                                                >
                                                    <DeleteIconWrapper />
                                                    {/* <Icon url={DeleteIcon} width={24} /> */}
                                                </Button>
                                            </Tooltip>
                                        )
                                    }
                                </>
                            )
                    }
                </div>
            </div>

            <div className="service-data">
                {
                    companyName && (
                        <div className="service-card--item service-company">
                            <span>{t('home_page__company_name')}</span>
                            <span>{ companyName }</span>
                        </div>
                    )
                }

                <div className="service-card--item service-date">
                    <span>{t('home_page__last_sync_date')}</span>
                    <span>{ !lastSyncDate ? '' : formatDMY(lastSyncDate) }</span>
                </div>

                <div className="service-card--item service-status">
                    <span>{t('home_page__last_sync_status')}</span>
                    {
                        (lastSyncStatus !== null)
                            // eslint-disable-next-line no-template-curly-in-string
                            ? (
                                <span className={`status-component ${lastSyncStatus === 'failed' ? 'fail' : ''}`}>
                                    <Icon url={lastSyncStatus === 'failed' ? ErrorIcon : TickIcon} width={24} />
                                    { lastSyncStatus === 'failed' ? t('Fail') : t('Success') }
                                </span>
                            )
                            : <span />
                    }
                </div>

                <div className="service-card--item service-subscribe">
                    <span>{t('home_page__backup_status')}</span>
                    <Switch
                      disabled={loading}
                      checked={active}
                      onChange={() => toggleSwitch(record)}
                    />
                </div>
            </div>
            {/* Modal service actions */}
            <AntModal
              closable={false}
              destroyOnClose
              visible={show}
              className="mobile-modal"
              maskClosable={false}
              footer={null}
              transitionName=""
            >
                <ul {...handlers}>
                    <div className="line">
                        <Icon url={LineIcon} />
                    </div>
                    <li
                      style={{ display: display ? 'block' : 'none' }}
                      // eslint-disable-next-line
                      role="button"
                      tabIndex={0}
                      onClick={() => history.push({
                            pathname: '/audit_of_actions',
                            state: { id: subscriptionId },
                        })}
                      onKeyDown={() => history.push({
                          pathname: '/audit_of_actions',
                          state: { id: subscriptionId },
                      })}
                    >
                        <Icon url={HistoryIcon} width={24} />
                        <p>{t('home_page__show_events')}</p>
                    </li>
                    <li
                      style={{ display: display ? 'block' : 'none' }}
                      // eslint-disable-next-line
                      role="button"
                      tabIndex={0}
                      onClick={() => restore(record)}
                      onKeyDown={() => restore(record)}
                    >
                        <Icon url={DownloadIcon} width={24} />
                        <p>{t('Download')}</p>
                    </li>
                    <li
                      // eslint-disable-next-line
                      role="button"
                      tabIndex={0}
                      style={{ pointerEvents: record.active ? 'none' : 'auto' }}
                      onClick={() => remove(record)}
                      onKeyDown={() => remove(record)}
                    >
                        <Icon url={DeleteIcon} width={24} />
                        <p>{t('Delete')}</p>
                    </li>
                </ul>
            </AntModal>
        </div>
    );
}

export default ServiceCard;

ServiceCard.defaultProps = {
    loading: false,
};
