import { getCookie } from '../helpers/util';

export const commonServices = {
    downloadKeys,
    getPassword,
    sendKeysByEmail,
    signInViaQuickbooks,
    signInViaSlack,
    signInViaGithub,
    getCoInfo,
    signInViaHubspot,
    signInViaSalesforce,
    signInViaShopify,
    sendEmailToSupport,
    getSubscriptionsList,
    getAuditOfActions,
};

// ======== Deprecated =========
function downloadKeys() {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/keys/private/download`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then(async (response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            const contentDispositionHeader: string | null = response.headers.get('content-disposition');
            if (contentDispositionHeader === null) throw Error('Content-Disposition is not provided!');
            const index = contentDispositionHeader.indexOf('filename=');
            const filename = contentDispositionHeader.substr(index + 9);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            // @ts-ignore
            link.parentNode.removeChild(link);
        });
}

// ========= Deprecated =========
function getPassword() {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/keys/passphrase`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        });
}

function sendKeysByEmail() {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/keys/send-by-email`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function signInViaQuickbooks(code: string, redirect_uri: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/quickbooks`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ code, redirect_uri }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function signInViaSlack(code: string, redirect_uri: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/slack`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ code, redirect_uri }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        });
}

function signInViaGithub(code: string, redirect_uri: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/github`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ code, redirect_uri }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        });
}

function signInViaHubspot(code: string, redirect_uri: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/hubspot`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ code, redirect_uri }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function signInViaShopify(code: string, shop: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/shopify`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ code, shop }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function getCoInfo(params: object, token: object) {
    // @ts-ignore
    const { access_token } = token;
    // @ts-ignore
    const { realmId } = params;
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/quickbooks-details`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ access_token, realmId }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function signInViaSalesforce(code: string, instanceName: string) {
    const redirect_uri = `${window.location.origin}/signin/salesforce`;
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/signin/salesforce`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ code, redirect_uri, instanceName }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function sendEmailToSupport(subject: string, body: string) {
    // let emails: Array<string> | null = null;
    // if (cc !== undefined) {
    //     emails = cc;
    // }
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/support/send-mail`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ cc: null, subject, body }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

// Used in Audit of Actions page ('/audit_of_actions')
function getSubscriptionsList() {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/user/events/subscriptions?page=0&size=100`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response: Response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function getAuditOfActions(id: string, page: number, size: number) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/user/events?page=${page - 1}&size=${size}&subscriptionId=${id}`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response: Response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}
