import React, {
    useState, useMemo, useCallback, useEffect, useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { BrowserView, TabletView, MobileView } from 'react-device-detect';
import { Menu } from 'antd';
import { BaseDataContext } from '../../contexts/BaseContext';
// components
import Icon from '../Icon';
import LanguageSelector from '../LanguageSelector';
import Account from './Account';
// utils
import { getCookie } from '../../helpers/util';
// assets
import LogoIcon from '../../assets/images/logo.png';
import LogoWhiteIcon from '../../assets/images/logo_white.png';
import './Navbar.scss';
import ThemeSwitch from '../ThemeSwitch';

// eslint-disable-next-line no-unused-vars
function Navigation() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [toggle, setToggle] = useState(false);

    const { contextState } = useContext(BaseDataContext);

    const token: string | undefined = getCookie('token');

    const handleClick = useCallback(({ key }: { key: string }) => {
        if (key.startsWith('external-link') || key === 'menu' || key === 'lang' || key === 'account') return;
        if (key === 'privacy') {
            window.location.href = 'https://www.fatbrain.ai/privacy-policy/';
        }
        if (key === 'terms_of_service') {
            window.location.href = 'https://www.fatbrain.ai/terms/';
        }
        setToggle(false);
        history.push(`/${key}`);
    }, [toggle]);

    const handleToggle = useCallback(() => {
        setToggle(!toggle);
    }, [toggle]);

    const desktopMenu = useMemo(() => {
        if (!token) {
            return <div />;
        }
        const items = [
            { key: '', label: t('navbar_component__my_data') },
            { key: 'support', label: t('navbar_component__support') },
            { key: 'contacts', label: t('navbar_component__contact') },
            { key: 'about', label: t('navbar_component__about') },
            { key: 'account', label: <Account /> },
        ];
        return (
            <div className="menu-topbar--container">
                <div
                  className="app-logo"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => history.push('/')}
                  onClick={() => history.push('/')}
                  // eslint-disable-next-line
                >
                    <Icon url={contextState.theme === 'light' ? LogoWhiteIcon : LogoIcon} alt="Fatbrain Logo" width={170} />
                </div>
                <Menu className="desktop-menu" mode="horizontal" onClick={handleClick} items={items} />
            </div>
        );
    }, [token, t, contextState.theme]);

    const mobileMenu = useMemo(() => {
        if (!token) {
            return <div />;
        }
        const items = [
            { key: '', label: t('navbar_component__my_data') },
            { key: 'support', label: t('navbar_component__support') },
            { key: 'contacts', label: t('navbar_component__contact') },
            { key: 'about', label: t('navbar_component__about') },
            {
                key: 'account',
                label: (
                    <div className="account-wrapper">
                        <h5>{t('navbar_component__profile')}</h5>
                        <p>{contextState.user.email}</p>
                    </div>
                ),
            },
            { key: 'privacy', label: t('app_page__privacy_policy') },
            { key: 'terms_of_service', label: t('app_page__terms_of_service') },
            { key: 'login', label: t('navbar_component__logout') },
        ];
        return (
            <nav className="mobile-view--wrapper">
                <Icon url={contextState.theme === 'light' ? LogoWhiteIcon : LogoIcon} alt="Fatbrain Logo" width={200} />
                <div className="menu-wrap">
                    <input
                      type="checkbox"
                      className="toggler"
                      checked={toggle}
                      onChange={handleToggle}
                    />
                    <div className="burger">
                        <div />
                    </div>
                    <div className="menu">
                        <h5>{t('navbar_component__menu')}</h5>
                        <Menu
                          defaultSelectedKeys={['']}
                          mode="inline"
                          theme="light"
                          onClick={handleClick}
                          items={items}
                        />
                        <div className="menu-footer">
                            <LanguageSelector />
                            <ThemeSwitch />
                        </div>
                    </div>
                </div>
            </nav>
        );
    }, [token, toggle, t, contextState.theme]);

    useEffect(() => {
        if (!token) {
            const path = location.pathname;
            if (path !== '/login' && path !== '/signup' && path !== '/forgot-password' && path !== '/confirm-signup') {
                history.push('/login');
            }
        }
    });

    const isSidebarMenu = window.innerWidth <= 768;

    return (
        isSidebarMenu
            ? mobileMenu
            : desktopMenu
    );
}

export default Navigation;
