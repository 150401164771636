import React from 'react';
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t } = useTranslation();
    return (
        <>
            <section className="services-page--header">
                <h2>{t('contact_page__contact')}</h2>
            </section>
            <section className="services-page--wrapper contact-page--body">
                <p>
                    {t('contact_page__if_you_have_any_questions')}
                    <a href="mailto:ransomproof-support@fatbrain.ai">ransomproof-support@fatbrain.ai</a>
                    {t('contact_page__and_we_will_respond')}
                </p>
            </section>
        </>
    );
}

export default Contact;
