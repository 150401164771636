import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from '../../helpers/util';

export function PrivateRoute({ component, ...rest }: any) {
    const token = getCookie('token');

    return (
        <Route
          {...rest}
          render={(props: any) => (token ? (
                React.createElement(component, { ...props })
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            ))}
        />
    );
}
