import React, { forwardRef, LegacyRef } from 'react';

const SlackLogotype = forwardRef((props: { color: string }, ref: LegacyRef<SVGSVGElement>) => {
    const { color } = props;
    return (
        <svg ref={ref} clipRule="evenodd" fillRule="evenodd" viewBox="0 0 560 400" xmlns="http://www.w3.org/2000/svg">
            <path d="m215.939 225.868 4.443-10.318c4.801 3.583 11.178 5.446 17.484 5.446 4.658 0 7.596-1.791 7.596-4.514-.072-7.596-27.874-1.648-28.089-20.709-.072-9.674 8.527-17.126 20.709-17.126 7.237 0 14.475 1.791 19.634 5.876l-4.156 10.533c-4.729-3.01-10.605-5.159-16.194-5.159-3.798 0-6.306 1.791-6.306 4.084.072 7.452 28.089 3.368 28.376 21.568 0 9.889-8.384 16.839-20.422 16.839-8.814 0-16.911-2.078-23.073-6.521m170.47-14.045c-2.221 3.869-6.377 6.521-11.178 6.521-7.094 0-12.826-5.732-12.826-12.826s5.732-12.826 12.826-12.826c4.801 0 8.957 2.651 11.178 6.521l12.253-6.807c-4.586-8.169-13.4-13.758-23.432-13.758-14.833 0-26.871 12.038-26.871 26.871s12.038 26.871 26.871 26.871c10.104 0 18.846-5.518 23.432-13.758l-12.253-6.807zm-120.812-55.462h15.334v75.024h-15.334zm139.084 0v75.024h15.334v-22.5l18.201 22.5h19.634l-23.145-26.728 21.425-24.936h-18.774l-17.341 20.709v-44.069zm-78.177 55.605c-2.221 3.654-6.807 6.377-11.967 6.377-7.094 0-12.826-5.732-12.826-12.826s5.732-12.826 12.826-12.826c5.159 0 9.745 2.866 11.967 6.592zm0-32.245v6.091c-2.508-4.228-8.742-7.166-15.263-7.166-13.471 0-24.076 11.895-24.076 26.799s10.605 26.943 24.076 26.943c6.521 0 12.755-2.938 15.263-7.166v6.091h15.334v-51.592z" fill={color} />
            <path d="m121.138 211.895c0 5.231-4.228 9.459-9.459 9.459s-9.459-4.228-9.459-9.459 4.228-9.459 9.459-9.459h9.459zm4.729 0c0-5.231 4.228-9.459 9.459-9.459s9.459 4.228 9.459 9.459v23.647c0 5.231-4.228 9.459-9.459 9.459s-9.459-4.228-9.459-9.459z" fill="#e01e5a" />
            <path d="m135.326 173.917c-5.231 0-9.459-4.228-9.459-9.459s4.228-9.459 9.459-9.459 9.459 4.228 9.459 9.459v9.459zm0 4.801c5.231 0 9.459 4.228 9.459 9.459s-4.228 9.459-9.459 9.459h-23.718c-5.231 0-9.459-4.228-9.459-9.459s4.228-9.459 9.459-9.459z" fill="#36c5f0" />
            <path d="m173.232 188.177c0-5.231 4.228-9.459 9.459-9.459s9.459 4.228 9.459 9.459-4.228 9.459-9.459 9.459h-9.459zm-4.729 0c0 5.231-4.228 9.459-9.459 9.459s-9.459-4.228-9.459-9.459v-23.718c0-5.231 4.228-9.459 9.459-9.459s9.459 4.228 9.459 9.459z" fill="#2eb67d" />
            <path d="m159.045 226.083c5.231 0 9.459 4.228 9.459 9.459s-4.228 9.459-9.459 9.459-9.459-4.228-9.459-9.459v-9.459zm0-4.729c-5.231 0-9.459-4.228-9.459-9.459s4.228-9.459 9.459-9.459h23.718c5.231 0 9.459 4.228 9.459 9.459s-4.228 9.459-9.459 9.459z" fill="#ecb22e" />
        </svg>
    );
});

export default SlackLogotype;
