import React, {
    useContext, useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Tooltip } from 'antd';
import { BaseDataContext } from '../../contexts/BaseContext';
import { inputStatus } from '../../types/common';
import Button from '../Button';
import Help from '../Shopify/Help';
import { valueDefined } from '../../helpers/util';

interface IGithubProps {
    id?: string;
    subscriptionId?: string;
    initial?: any;
    alias?: string
}

function Github({
    id, subscriptionId, initial, alias,
}: IGithubProps) {
    const { contextState } = useContext(BaseDataContext);
    const { backup } = contextState;
    const { t } = useTranslation();

    const [state, setState] = useState({
        username: '',
        repository: '',
    });
    const [valid, setValid] = useState<inputStatus>();

    const handleChangeState = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }, []);

    useEffect(() => {
        const githubService = backup.subscriptions.filter((item) => (
            item.service === 'github' && item?.serviceId === initial?.credentials?.client_id
        ));
        if (githubService.length) {
            setValid('error');
            return;
        }
        setValid('success');
    }, [backup.subscriptions]);

    useEffect(() => {
        if (!initial) return;
        if (!alias) return;
        const { repository } = initial;
        setState({
            username: alias,
            repository,
        });
    }, [initial, alias]);

    const handleClickSignIn = () => {
        let host = `${window.location.origin}/signin/github`;
        // change redirect url if it's resubscribe flow
        if (id && subscriptionId) {
            const githubService = backup.subscriptions.filter((item) => (
                item.id === id
            ));
            if (!githubService.length) return;
            host = `${window.location.origin}/resubscribe/github`;
            localStorage.setItem('serviceName', githubService[0].serviceIdAlias);
            localStorage.setItem('serviceID', subscriptionId);
        }
        localStorage.setItem('username', state.username);
        localStorage.setItem('repoName', state.repository);
        const { REACT_APP_GITHUB_CLIENT_ID, REACT_APP_GITHUB_SCOPES } = process.env;
        window.location.replace(`https://github.com/login/oauth/authorize?client_id=${REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${host}&login=${state.username}&scope=${REACT_APP_GITHUB_SCOPES}`);
    };

    return (
        <>
            <div className="selected-service-header">
                <p className="definition">
                    Here will be definition...
                </p>
            </div>
            <div className="selected-service-body">
                {
                    valid === 'error' ? (
                        <p>{t('multiple_instance')} Slack.</p>
                    ) : (
                        <>
                            <Form.Item
                              rules={[{ required: true, message: t('github_component__fill_github_username') }]}
                              validateStatus={valid}
                              // eslint-disable-next-line
                            >
                                <Input
                                  name="username"
                                  value={state.username}
                                  disabled={!!initial}
                                  onChange={handleChangeState}
                                  placeholder={`${t('github_component__type_username')} ...`}
                                  className="service-name--input"
                                  suffix={(
                                      <Tooltip
                                        title={t('NOTE: Space-delimited list of GitHub organizations/repositories, e.g. organization/awesome-project  for single repository, organization/*  for get all repositories from organization and organization/project1 organization/another-project  for multiple repositories. (e.g. organization/project1 organization/another-repo, organization/*, organization/foobar)')}
                                        overlayClassName={`${contextState.theme === 'light' ? 'light' : 'dark'} input-tooltip-style`}
                                        placement="right"
                                      >
                                          <span>
                                              <Help color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} className="" />
                                          </span>
                                      </Tooltip>
                                    )}
                                />
                            </Form.Item>

                            <Form.Item
                              rules={[{ required: true, message: t('github_component__fill_repository') }]}
                              validateStatus={valid}
                                // eslint-disable-next-line
                            >
                                <Input
                                  name="repository"
                                  value={state.repository}
                                  disabled={!!initial}
                                  onChange={handleChangeState}
                                  placeholder={`${t('github_component__fill_repository')} ...`}
                                  className="service-name--input"
                                  suffix={(
                                      <Tooltip
                                        title={t('Please fill your github account repository name')}
                                        overlayClassName={`${contextState.theme === 'light' ? 'light' : 'dark'} input-tooltip-style`}
                                        placement="right"
                                      >
                                          <span>
                                              <Help color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} className="" />
                                          </span>
                                      </Tooltip>
                                    )}
                                />
                            </Form.Item>

                            <Button
                              onClick={handleClickSignIn}
                              className="create-btn"
                              disabled={
                                !(valueDefined(state.username) || !(valueDefined(state.repository)))
                              }
                              // eslint-disable-next-line
                            >
                                {t('Sign_in_via')} Github
                            </Button>
                        </>
                    )
                }
            </div>
        </>
    );
}

export default Github;

Github.defaultProps = {
    id: undefined,
    subscriptionId: undefined,
    initial: undefined,
    alias: undefined,
};
