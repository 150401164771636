import React, {
    FC, useState, useMemo, useCallback, SyntheticEvent,
} from 'react';
// utils
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../helpers/util';
// components
import ProgressChart from '../../../components/ProgressChart';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
// types
import { IDownloadsFile } from '../types';
import { ColorMap } from '../../../components/ProgressChart/types';
import { IIcon } from '../../Service';
// assets
import ShopifyIcon from '../../../assets/images/svg/shopify.svg';
import QuickbooksIcon from '../../../assets/images/svg/quickbooks.svg';
import SalesforceIcon from '../../../assets/images/svg/salesforce.svg';
import SlackIcon from '../../../assets/images/svg/slack.svg';
import HubspotIcon from '../../../assets/images/svg/hubspot.svg';
import RefreshIcon from '../../../assets/images/svg/refresh.svg';
import './styles.scss';

interface IDownloadsCardProps {
    record: IDownloadsFile;
    // eslint-disable-next-line no-unused-vars
    onDownload: (i: IDownloadsFile) => Promise<boolean>;
    // eslint-disable-next-line no-unused-vars
    onRefresh: (i: IDownloadsFile) => Promise<boolean>;
}

const DownloadsCard: FC<IDownloadsCardProps> = ({
    record, onDownload, onRefresh,
}) => {
    const { t } = useTranslation();
    const {
        serviceCode, serviceIdAlias, createdAt, eventStatus,
    } = record;

    const [loading, setLoading] = useState(false);

    const activeTillDate = moment(createdAt).add(5, 'days').format('MM/DD/YYYY HH:mm:ss');
    const isExpired = +(new Date()) > +(new Date(activeTillDate));

    const colors: ColorMap = useMemo(() => ({
        done: '#73BF16',
        error: '#FF403D',
        new: '#0EC7D9',
        expired: '#FFEF11',
    }), []);

    const handleClick = useCallback(async (e: SyntheticEvent<HTMLButtonElement>) => {
        setLoading(true);
        const { name } = e.currentTarget;
        if (name === 'refresh') {
            await onRefresh(record);
        }
        if (name === 'download') {
            await onDownload(record);
        }
        setLoading(false);
    }, [record]);

    const icons: IIcon = {
        shopify: ShopifyIcon,
        quickbooks: QuickbooksIcon,
        salesforce: SalesforceIcon,
        slack: SlackIcon,
        hubspot: HubspotIcon,
    };

    const statusText: ColorMap = {
        done: t('Ready for download'),
        error: t('Failed'),
        new: t('New'),
        expired: t('downloads_page__expired'),
    };

    return (
        <div className="downloadCard-component--wrapper">
            <div>
                <div className="left-column">
                    <ProgressChart status={record.eventStatus} colors={colors} />
                </div>
                <div className="right-column">
                    <span>
                        <Icon url={icons[serviceCode]} width={18} />
                        {capitalizeFirstLetter(serviceIdAlias)}
                    </span>
                    <span style={{ color: colors[eventStatus] ?? '#FFEF11' }}>{ statusText[eventStatus] ?? t('In progress') }</span>
                    <span>{activeTillDate}</span>
                </div>
            </div>
            <div className="actions--wrapper">
                <Button
                  type="link"
                  name="download"
                  loading={loading}
                  className="downloadCard-download--btn"
                  onClick={handleClick}
                  disabled={eventStatus !== 'done' || isExpired}
                  title={isExpired ? t('downloads_page__expired_no_available') : ''}
                >
                    {t('Download')}
                </Button>
                <Button
                  type="link"
                  name="refresh"
                  loading={loading}
                  className="downloadCard-download--btn"
                  icon={<Icon url={RefreshIcon} width={18} />}
                  onClick={handleClick}
                />
            </div>
        </div>
    );
};

export default DownloadsCard;
