import React, { forwardRef, LegacyRef } from 'react';

const ShopifyLogotype = forwardRef((props: { color: string }, ref: LegacyRef<SVGSVGElement>) => {
    const { color } = props;
    return (
        <svg ref={ref} width="123" height="35" viewBox="0 0 123 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_35_4516)">
                <path d="M26.5313 6.78097C26.5075 6.60955 26.356 6.51445 26.2304 6.5041C26.1056 6.49374 23.662 6.45702 23.662 6.45702C23.662 6.45702 21.6183 4.49441 21.4164 4.29475C21.2146 4.09511 20.8203 4.15538 20.667 4.20058C20.6651 4.20152 20.2832 4.3183 19.6404 4.51512C19.5328 4.17045 19.3747 3.74665 19.149 3.32098C18.4215 1.94791 17.3558 1.22182 16.0683 1.21994C16.0664 1.21994 16.0654 1.21994 16.0635 1.21994C15.974 1.21994 15.8854 1.22842 15.7959 1.23595C15.7578 1.19075 15.7197 1.14649 15.6798 1.10316C15.1188 0.509861 14.3999 0.220744 13.538 0.246171C11.8753 0.293259 10.2192 1.48081 8.87648 3.59033C7.93178 5.07452 7.21278 6.93921 7.009 8.3829C5.09961 8.96771 3.76447 9.37641 3.73495 9.38586C2.77121 9.68531 2.74074 9.7145 2.61504 10.613C2.52171 11.2919 0 30.5753 0 30.5753L21.1327 34.1897L30.2919 31.9379C30.2919 31.9379 26.5551 6.9524 26.5313 6.78097ZM18.5824 4.83908C18.0958 4.98788 17.5424 5.1574 16.9425 5.34104C16.9301 4.50853 16.8301 3.35018 16.4378 2.3491C17.6996 2.58548 18.3205 3.99716 18.5824 4.83908ZM15.8369 5.68007C14.7293 6.01908 13.5208 6.38919 12.3086 6.76027C12.6495 5.46912 13.2961 4.18363 14.0904 3.34076C14.3856 3.02716 14.7988 2.67777 15.2883 2.47812C15.7483 3.4274 15.8483 4.77128 15.8369 5.68007ZM13.5713 1.34048C13.9618 1.33201 14.2903 1.41677 14.5713 1.59947C14.1218 1.8302 13.6875 2.16169 13.2799 2.59395C12.2238 3.71464 11.4143 5.45405 11.0915 7.13225C10.0849 7.44019 9.10023 7.74252 8.19363 8.0194C8.76601 5.37777 11.0048 1.41394 13.5713 1.34048Z" fill="#95BF47" />
                <path d="M26.2312 6.50505C26.1065 6.49469 23.6629 6.45798 23.6629 6.45798C23.6629 6.45798 21.6193 4.49535 21.4174 4.29571C21.3421 4.2213 21.2402 4.18269 21.1335 4.16669L21.1345 34.1888L30.2929 31.938C30.2929 31.938 26.556 6.95335 26.5322 6.78193C26.5084 6.6105 26.356 6.5154 26.2312 6.50505Z" fill="#5E8E3E" />
                <path d="M16.0569 11.1621L14.9932 15.0976C14.9932 15.0976 13.8067 14.5636 12.4001 14.6512C10.3374 14.7803 10.3155 16.0667 10.3365 16.3897C10.4488 18.1498 15.1313 18.5341 15.3942 22.657C15.6008 25.9004 13.6543 28.1192 10.8497 28.2943C7.48331 28.5043 5.63013 26.5408 5.63013 26.5408L6.34342 23.5404C6.34342 23.5404 8.20896 24.9323 9.70218 24.8391C10.6774 24.7779 11.0259 23.9934 10.9906 23.4387C10.844 21.1427 7.03096 21.2783 6.79005 17.5056C6.58719 14.331 8.69564 11.114 13.3476 10.824C15.1399 10.71 16.0569 11.1621 16.0569 11.1621Z" fill="white" />
                <path d="M42.3532 19.403C41.3 18.8379 40.7588 18.3616 40.7588 17.7068C40.7588 16.8736 41.5106 16.3383 42.6847 16.3383C44.0515 16.3383 45.2718 16.9034 45.2718 16.9034L46.2343 13.9873C46.2343 13.9873 45.3493 13.3026 42.7442 13.3026C39.1191 13.3026 36.6066 15.3557 36.6066 18.242C36.6066 19.8784 37.7797 21.1282 39.3448 22.0203C40.6086 22.7339 41.0601 23.2402 41.0601 23.9837C41.0601 24.7571 40.4282 25.382 39.2551 25.382C37.5067 25.382 35.8557 24.489 35.8557 24.489L34.8328 27.4052C34.8328 27.4052 36.3582 28.4168 38.9245 28.4168C42.6555 28.4168 45.3332 26.6019 45.3332 23.3289C45.3323 21.5747 43.9788 20.3249 42.3532 19.403Z" fill={color} />
                <path d="M57.2167 13.2737C55.3813 13.2737 53.9371 14.1368 52.8244 15.4454L52.7639 15.4155L54.3583 7.17419H50.2061L46.1748 28.1497H50.327L51.7108 20.9798C52.252 18.2719 53.666 16.6064 54.9902 16.6064C55.9225 16.6064 56.2843 17.2313 56.2843 18.1243C56.2843 18.6894 56.2238 19.3741 56.1038 19.9392L54.5397 28.1506H58.6921L60.3163 19.6711C60.497 18.7781 60.6178 17.7077 60.6178 16.9931C60.616 14.672 59.3826 13.2737 57.2167 13.2737Z" fill={color} />
                <path d="M70.0031 13.2736C65.0081 13.2736 61.6997 17.7367 61.6997 22.705C61.6997 25.8883 63.6849 28.4467 67.4161 28.4467C72.32 28.4467 75.6296 24.1033 75.6296 19.0154C75.6296 16.0702 73.8841 13.2736 70.0031 13.2736ZM67.9573 25.2942C66.543 25.2942 65.9412 24.1042 65.9412 22.6162C65.9412 20.2661 67.1752 16.428 69.4315 16.428C70.9058 16.428 71.3864 17.6778 71.3864 18.8977C71.3864 21.4262 70.1541 25.2942 67.9573 25.2942Z" fill={color} />
                <path d="M86.2499 13.2736C83.4472 13.2736 81.8568 15.7134 81.8568 15.7134H81.7973L82.0381 13.5118H78.3676C78.1874 14.9998 77.8556 17.2602 77.5255 18.9565L74.637 33.9811H78.7892L79.9317 27.9115H80.0226C80.0226 27.9115 80.8745 28.4467 82.4598 28.4467C87.3334 28.4467 90.5223 23.5083 90.5223 18.5091C90.5223 15.7433 89.2883 13.2736 86.2499 13.2736ZM82.2784 25.353C81.2006 25.353 80.5627 24.758 80.5627 24.758L81.2549 20.92C81.7366 18.3615 83.0902 16.6652 84.5347 16.6652C85.7985 16.6652 86.1892 17.8254 86.1892 18.9267C86.1892 21.5747 84.5948 25.353 82.2784 25.353Z" fill={color} />
                <path d="M96.4492 7.38251C95.126 7.38251 94.0728 8.424 94.0728 9.76251C94.0728 10.9824 94.8548 11.8156 96.0282 11.8156H96.0882C97.3823 11.8156 98.495 10.9525 98.5253 9.43562C98.5253 8.24556 97.713 7.38251 96.4492 7.38251Z" fill={color} />
                <path d="M90.6431 28.1497H94.7942L97.6232 13.6015H93.4406L90.6431 28.1497Z" fill={color} />
                <path d="M108.183 13.5716H105.295L105.445 12.8869C105.686 11.4886 106.528 10.2388 107.912 10.2388C108.651 10.2388 109.236 10.4471 109.236 10.4471L110.048 7.23396C110.048 7.23396 109.326 6.87714 107.791 6.87714C106.317 6.87714 104.844 7.2937 103.73 8.24552C102.316 9.43557 101.654 11.1608 101.323 12.8869L101.203 13.5716H99.2771L98.6753 16.6662H100.602L98.4053 28.1506H102.558L104.754 16.6662H107.612L108.183 13.5716Z" fill={color} />
                <path d="M118.171 13.6015C118.171 13.6015 115.576 20.0681 114.41 23.598H114.35C114.271 22.4612 113.327 13.6015 113.327 13.6015H108.964L111.462 26.9597C111.523 27.2567 111.493 27.4361 111.372 27.6443C110.89 28.5663 110.078 29.4593 109.115 30.1141C108.333 30.6791 107.461 31.0359 106.769 31.2741L107.912 34.7554C108.755 34.577 110.499 33.8924 111.973 32.5239C113.869 30.7688 115.614 28.0609 117.419 24.3713L122.503 13.6006H118.171V13.6015Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_35_4516">
                    <rect width="123" height="34.5108" fill={color} transform="translate(0 0.244629)" />
                </clipPath>
            </defs>
        </svg>

    );
});

export default ShopifyLogotype;
