import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Modal as AntModal } from 'antd';
import Button from '../Button';
import Close from './Close';
import { BaseDataContext } from '../../contexts/BaseContext';
// assets
import './Modal.scss';

interface IProps {
    // eslint-disable-next-line no-undef
    children: JSX.Element,
    visible: boolean,
    title: string,
    onSave: Function,
    onCancel: Function,
    saveText?: string,
    disableSave?: boolean,
    destroyOnClose?: boolean,
    closable?: boolean,
    className?: string,
}

function Modal({
    children, visible, title, onSave, onCancel, saveText, disableSave, destroyOnClose, closable,
    className,
}: IProps) {
    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);

    return (
        <AntModal
          closable={closable}
          destroyOnClose={destroyOnClose}
          visible={visible}
          title={title}
          closeIcon={<Close light={contextState.theme === 'light'} />}
          className={`custom-modal ${className}`}
          maskClosable={false}
          width={450}
          // @ts-ignore
          onCancel={onCancel}
          footer={[
              <Button key="back" onClick={onCancel} className="modal-cancel--btn">
                  {t('Cancel')}
              </Button>,
              <Button
                disabled={disableSave}
                key="submit"
                type="primary"
                onClick={onSave}
                className="modal-save--btn"
              >
                  { saveText }
              </Button>,
          ]}
          // eslint-disable-next-line
        >
            { children }
        </AntModal>
    );
}

export default Modal;

Modal.defaultProps = {
    saveText: 'Save',
    disableSave: false,
    destroyOnClose: true,
    closable: true,
    className: '',
};
