import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { EyeTwoTone, EyeInvisibleTwoTone } from '@ant-design/icons';
// Components
import {
    Form,
    Input,
    Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Notification from '../Notification';
import Icon from '../Icon';
import EmailConfirmation from '../ConfirmSignup/EmailConfirmation';
// utils
import { authenticationService } from '../../services/authentication';
import { BaseDataContext } from '../../contexts/BaseContext';
// Assets
import LogoIcon from '../../assets/images/logo.png';
import SmsIcon from '../../assets/images/svg/sms.svg';
import PasswordIcon from '../../assets/images/svg/password.svg';
import AccountIcon from '../../assets/images/svg/account.svg';
import LogoWhiteIcon from '../../assets/images/logo_white.png';

function SignUp() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [to, setTo] = useState();
    const history = useHistory();

    const { contextState } = useContext(BaseDataContext);

    const onFinish = async (values: any) => {
        const { email, password, name } = values;
        setIsLoading(true);
        try {
            const response = await authenticationService.signup(email, password, name);
            if (response.status === 'failed') throw Error(response.message);
            setTo(email);
            Notification({
                type: 'success',
                message: t('Success_message'),
                description: `${t('auth_page__sent_an_email')}: ${email} ${t('auth_page__with_secret_code')}`,
                duration: 2,
            });
        } catch (err: any) {
            console.error(err);
            Notification({
                type: 'error',
                message: t('Error message'),
                description: t(err.message),
                duration: 2,
            });
            setIsLoading(false);
        }
    };

    return (
        <section>
            {
                to !== undefined
                    ? <EmailConfirmation email={to} />
                    : (
                        <div className="auth-modal--wrapper">
                            <Icon url={contextState.theme === 'light' ? LogoWhiteIcon : LogoIcon} alt="Fatbrain Logo" width={200} />
                            <div>
                                <div className="login_form--container">
                                    <h5>{t('auth_page__sign_up')}</h5>
                                    <p>{t('auth_page__please_enter_your_details')}</p>
                                    <Form
                                      name="basic"
                                      layout="vertical"
                                      initialValues={{ remember: false }}
                                      onFinish={onFinish}
                                      autoComplete="off"
                                      className="login-form"
                                    >
                                        <Form.Item
                                          name="email"
                                          rules={[{ required: true, message: t('auth_page__please_input_your_email') }]}
                                          className="custom-input custom-input--text"
                                        >
                                            <Input prefix={<Icon url={SmsIcon} />} placeholder={t('auth_page__enter_your_email')} />
                                        </Form.Item>

                                        <Form.Item
                                          name="password"
                                          rules={[
                                              { required: true, message: `- ${t('auth_page__please_input_your_password')}` },
                                              { type: 'string', min: 8, message: `- ${t('auth_page__at_least_8_characters')}!` },
                                              {
                                                  validator(_, value) {
                                                      if (value.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]')) {
                                                          return Promise.resolve();
                                                      }
                                                      return Promise.reject(new Error(`- ${t('auth_page__uppercase_lowercase_number_symbol')}!`));
                                                  },
                                              },
                                          ]}
                                          className="custom-input custom-input--password"
                                        >
                                            <Input.Password
                                              prefix={<Icon url={PasswordIcon} />}
                                              // eslint-disable-next-line
                                              iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor='#0EC7D9' /> : <EyeInvisibleTwoTone twoToneColor='#0EC7D9' />)}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                          name="name"
                                          rules={[
                                              { required: true, message: t('auth_page__please_input_your_name') },
                                              // eslint-disable-next-line prefer-regex-literals
                                              { pattern: new RegExp(/^[a-zA-Z\s]*$/i), message: t('auth_page__only_letters') },
                                              ({ setFieldValue }) => ({
                                                  validator(_, value) {
                                                      if (value === ' ') {
                                                          setFieldValue('name', '');
                                                      }
                                                      return Promise.resolve();
                                                  },
                                              }),
                                          ]}
                                          className="custom-input custom-input--text"
                                        >
                                            <Input prefix={<Icon url={AccountIcon} />} placeholder={t('auth_page__enter_your_name')} />
                                        </Form.Item>

                                        <Form.Item name="remember" valuePropName="checked" className="form-actions">
                                            <Checkbox>{t('auth_page__remember_me')}</Checkbox>
                                        </Form.Item>

                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                                {t('auth_page__sign_up')}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <p className="switch-signup">
                                    {t('auth_page__already_have_an_account')}
                                    <Button
                                      type="link"
                                      size="small"
                                      onClick={() => history.push('/login')}
                                    >
                                        {t('auth_page__sign_in')}
                                    </Button>
                                </p>
                            </div>
                        </div>
                    )
            }
        </section>
    );
}

export default SignUp;
