import React from 'react';

export function BackwardIcon({ color }: { color: string }) {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2111 6.00003L5.85753 3.35359L5.15043 2.64648L1.29688 6.50004L5.15043 10.3536L5.85753 9.64648L3.21108 7.00003H10.5005V6.00003H3.2111Z" fill={color} />
        </svg>
    );
}

export function ForwardIcon({ color }: { color: string }) {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.79286 7.00003H1.5V6.00003H8.79285L6.14641 3.35359L6.85352 2.64648L10.7071 6.50004L6.85352 10.3536L6.14641 9.64648L8.79286 7.00003Z" fill={color} />
        </svg>
    );
}
