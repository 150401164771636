import React from 'react';
import { Table as AntTable, TableProps } from 'antd';
import './Table.scss';

interface ITableProps extends TableProps<any>{
    columns: Array<object>,
    data: Array<object>,
    locale?: object,
    rowKey: string,
    loading?: boolean,
    expandedRowRender?: any,
    expandIcon?: any,
    className?: string,
    showHeader?: boolean,
    indentSize?: number,
    bordered?: boolean,
    scroll?: object,
}

function Table({
    columns, data, locale, rowKey, loading, expandedRowRender, expandIcon, className,
    showHeader, indentSize, bordered, scroll, rowSelection,
}: ITableProps) {
    const props = {
        columns,
        dataSource: data,
        className: `custom-ant--table ${className}`,
        locale,
        rowKey,
        loading,
        expandedRowRender,
        expandIcon,
        showHeader,
        indentSize,
        bordered,
        scroll,
        rowSelection,
    };

    return (
        <AntTable pagination={false} {...props} />
    );
}

export default Table;

Table.defaultProps = {
    locale: undefined,
    loading: false,
    expandedRowRender: undefined,
    expandIcon: undefined,
    className: '',
    showHeader: true,
    indentSize: 15,
    bordered: false,
    scroll: undefined,
};
