import React, {
    ChangeEvent, KeyboardEvent, FC, useCallback, useContext, useMemo, useState,
} from 'react';
// utils
import { useTranslation } from 'react-i18next';
import {
    Checkbox, DatePicker, Input, Tooltip,
} from 'antd';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { BaseDataContext } from '../../contexts/BaseContext';
import { initialFilter } from './data';
// components
import Icon from '../Icon';
import Button from '../Button';
import Select from '../Select';
import FilterFooter from './Footer';
// types
import { IFilter } from '../../pages/DataRestore/RestoreFile/types';
import { RestoreFilterProps } from './types';
// icons
import CalendarIcon from '../../assets/images/svg/calendar-white.svg';
import DownIcon from '../../assets/images/svg/chevron-down.svg';
import SearchIcon from '../../assets/images/svg/search.svg';
import InfoIcon from '../../assets/images/svg/info.svg';

const { RangePicker } = DatePicker;

const RestoreFilter: FC<RestoreFilterProps> = ({ onSubmit }) => {
    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);

    const [showCalendar, setShowCalendar] = useState(false);
    const [filter, setFilter] = useState<IFilter>(initialFilter);

    const dates = useMemo<Array<{ code: string, title: string }>>(() => ([
        { code: 'today', title: t('Today') },
        { code: 'week', title: t('Week') },
        { code: 'month', title: t('Month') },
        { code: 'three_month', title: t('3 Month') },
        { code: 'year', title: t('Year') },
        { code: 'choose', title: t('Choose period') },
    ]), []);

    const handleChangeDate = useCallback((range) => {
        if (range === null) return;

        const [dateFrom, dateTo] = range;
        setFilter((prev) => ({
            ...prev,
            date: {
                ...prev.date,
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
            },
        }));
    }, []);

    const handleOpenChangeCalendar = useCallback((open: boolean) => {
        if (open) return;
        handleChangeDateSelect('');
    }, []);

    const handleApplyCalendar = useCallback(() => {
        setShowCalendar(false);
        const newFilter = {
            ...filter,
            date: {
                ...filter.date,
                code: '',
                value: `${filter.date.dateFrom} - ${filter.date.dateTo}`,
            },
        };
        setFilter(newFilter);
        onSubmit(newFilter);
    }, [filter]);

    const handleCancelCalendar = useCallback(() => {
        handleChangeDateSelect('');
    }, [filter]);

    const handleChangeDateSelect = useCallback((code: string) => {
        if (code === 'choose') {
            setShowCalendar(true);
            setFilter((prev) => ({
                ...prev,
                date: {
                    ...prev.date,
                    code,
                    value: '',
                },
            }));
            return;
        }
        if (code === '') {
            setShowCalendar(false);
            const resetFilter = {
                ...filter,
                date: {
                    ...filter.date,
                    code,
                    value: code,
                    dateTo: null,
                    dateFrom: null,
                },
            };
            setFilter(resetFilter);
            onSubmit(resetFilter);
            return;
        }
        const today = moment();

        let dateFrom: string | null = null;
        const dateTo: string = today.format('YYYY-MM-DD');
        const option = dates.filter((item) => item.code === code)[0];

        if (code === 'today') {
            dateFrom = today.format('YYYY-MM-DD');
        }
        if (code === 'week') {
            dateFrom = moment(today).subtract(7, 'd').format('YYYY-MM-DD');
        }
        if (code === 'month') {
            dateFrom = moment(today).subtract(1, 'month').format('YYYY-MM-DD');
        }
        if (code === 'three_month') {
            dateFrom = moment(today).subtract(3, 'month').format('YYYY-MM-DD');
        }
        if (code === 'year') {
            dateFrom = moment(today).subtract(1, 'year').format('YYYY-MM-DD');
        }
        const newFilter = {
            ...filter,
            date: {
                code,
                value: option.title,
                dateFrom,
                dateTo,
            },
        };
        setFilter(newFilter);
        onSubmit(newFilter);
    }, [filter]);

    const handleChangeSearch = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.currentTarget;
            const nextState = value === '' ? null : value;
            setFilter((prev) => ({
                ...prev,
                search: nextState,
            }));
        },
        [filter],
    );

    const handleKeyDownSearch = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            onSubmit(filter);
        }
    }, [onSubmit, filter]);

    const handleChangeCheckbox = useCallback(
        (event: CheckboxChangeEvent) => {
            const { checked } = event.target;
            const newFilter = {
                ...filter,
                isDeep: checked,
            };
            setFilter(newFilter);
            onSubmit(newFilter);
        },
        [filter],
    );

    const isMobile = window.innerWidth <= 480;

    return (
        <div className="restore-file--filter">
            <div className="restore-file-search--wrapper">
                <Input
                  size="large"
                  placeholder="Search"
                  className="search-input"
                  prefix={<Icon url={SearchIcon} />}
                  suffix={(
                      <Tooltip trigger={['hover', 'focus']} placement="bottom" title={t('data_restore_page__enter_to_start_search')}>
                          <Icon url={InfoIcon} />
                          <span />
                      </Tooltip>
                    )}
                  onChange={handleChangeSearch}
                  onKeyDown={handleKeyDownSearch}
                />
                <Checkbox name="isDeep" onChange={handleChangeCheckbox} checked={filter.isDeep}>
                    {t('data_restore_page__deep_search')}
                </Checkbox>
            </div>
            <div>
                {
                    isMobile
                        ? <Button icon={<Icon url={CalendarIcon} />} />
                        : (
                            <Input
                              size="large"
                              placeholder="Date"
                              value={filter.date.value}
                              className="datepicker-input"
                              prefix={<Icon url={CalendarIcon} />}
                              suffix={<Icon url={DownIcon} />}
                            />
                        )
                }
                {
                    showCalendar ? (
                        <RangePicker
                          defaultOpen
                          className="hidden-select"
                          dropdownClassName={`${contextState.theme === 'light' ? 'light' : 'dark'} custom-rangepicker-dropdown`}
                          placement="bottomRight"
                          onChange={handleChangeDate}
                          onOpenChange={handleOpenChangeCalendar}
                          renderExtraFooter={() => (
                              <FilterFooter
                                disableApply={
                                        filter.date.dateTo === null
                                        && filter.date.dateFrom === null
                                    }
                                onOk={handleApplyCalendar}
                                onCancel={handleCancelCalendar}
                              />
                            )}
                        />
                    ) : (
                        <Select
                          options={dates}
                          value={filter.date.code}
                          onChange={handleChangeDateSelect}
                          className="hidden-select"
                          dropdownClassName="hidden-select-dropdown"
                          placement="bottomRight"
                        />
                    )
                }
            </div>
        </div>
    );
};

export default RestoreFilter;
