import React, { useState, useMemo, useCallback } from 'react';
// components
import EnterEmail from './EnterEmail';
import EmailConfirmation from './EmailConfirmation';
import Icon from '../Icon';
// utils
import { valueDefined } from '../../helpers/util';
// assets
import LogoIcon from '../../assets/images/logo.png';

function ConfirmSignup() {
    const [email, setEmail] = useState('');

    const Handler = useMemo(() => {
        if (!valueDefined(email)) return EnterEmail;
        return EmailConfirmation;
    }, [email]);

    const onChange = useCallback((value) => {
        setEmail(value);
    }, []);

    return (
        <div className="auth-modal--wrapper">
            <Icon url={LogoIcon} alt="Fatbrain Logo" width={200} />
            <div>
                <Handler onChange={onChange} email={email} />
            </div>
        </div>
    );
}

export default ConfirmSignup;
