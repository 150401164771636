import React, {
    useEffect, useState, useCallback, useMemo, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { Spin } from 'antd';
import ServiceCard from './ServiceCard';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Notification from '../../components/Notification';
// types
import { IService, IState } from './types';
// utils
import { servicesService } from '../../services';
import { deleteCookie, getCookie, parseJwt } from '../../helpers/util';
import { BaseDataContext, IContextState } from '../../contexts/BaseContext';
// assets
import PlusIcon from '../../assets/images/svg/plus.svg';
import './Home.scss';

function Home() {
    const { t } = useTranslation();
    const history = useHistory();
    const { name }: { name: string | null } = parseJwt(getCookie('token') || null) || {};

    const isMounted = useRef(true);

    const [isLoading, setIsLoading] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean | IService>(false);

    const { contextState, setContextState } = useContext(BaseDataContext);
    const { backup } = contextState;

    const isMobile = window.innerWidth <= 480;

    const getServicesList = () => {
        setIsLoading(true);
        servicesService.get()
            .then((data: IState) => {
                if (!isMounted.current) return;
                // @ts-ignore
                setContextState((p: IContextState) => ({
                    ...p,
                    backup: data,
                }));
            })
            .catch((e: any) => {
                if (e.message === '401') {
                    deleteCookie('token');
                    history.push('/login');
                }
                console.error(e);
            })
            .finally(() => {
                if (!isMounted.current) return;
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getServicesList();
        return () => {
            isMounted.current = false;
        };
    }, []);
    useEffect(() => {
        if (!name) history.push('/');
    }, [name]);

    // Manage subscription when toggle switch component
    const handleToggleSwitch = useCallback(
        (record: IService) => {
            // If trying to resubscribe:
            if (!record.active) {
                history.push({
                    pathname: '/create',
                    state: {
                        service: record,
                    },
                });
                return;
            }
            // If trying to unsubscribe:
            const newServices: Array<IService> = backup.subscriptions.map((sub: IService) => {
                if (sub.id === record.id) {
                // eslint-disable-next-line no-param-reassign
                    sub.loading = true;
                }
                return sub;
            });
            // @ts-ignore
            setContextState((p: IContextState) => ({
                ...p,
                backup: {
                    ...p.backup,
                    subscriptions: newServices,
                },
            }));
            servicesService.unsubscribe(record.subscriptionId)
                .catch((err: any) => {
                    if (err.message === '401') {
                        deleteCookie('token');
                        history.push('/login');
                    }
                    if (!isMounted.current) return;
                    Notification({
                        type: 'error',
                        message: t('home_page__unsubscribe_failed'),
                        description: t(err.message),
                        duration: null,
                    });
                })
                .finally(() => {
                    if (!isMounted.current) return;
                    getServicesList();
                });
        },
        [backup.subscriptions],
    );

    const handleRestoreData = useCallback((record: IService) => {
        history.push({
            pathname: '/data-restore',
            // @ts-ignore
            service: record,
        });
    }, []);

    const handleRemoveSubscription = useCallback(async (item) => {
        setShowRemoveModal(false);
        try {
            const response = await servicesService.remove(item.subscriptionId);
            if (!isMounted.current) return;
            // @ts-ignore
            if (response.status !== 'success') throw Error(response.message);
            Notification({
                type: 'success',
                message: t('home_page__remove_success'),
                description: `${t('home_page__backup_with_id')}: ${item.service}: ${item.serviceIdAlias} ${t('home_page__successfully_removed')}!`,
                duration: null,
            });
            getServicesList();
        } catch (e:any) {
            if (e.message === '401') {
                deleteCookie('token');
                history.push('/login');
            }
            if (!isMounted.current) return;
            Notification({
                type: 'error',
                message: t('home_page__remove_failed'),
                description: t(e.message),
                duration: null,
            });
        }
    }, []);

    const HeaderActions = useMemo(() => (
        <>
            <Button
              type="link"
              onClick={() => history.push('/audit_of_actions')}
              className="header-auditOfActions--btn"
              // eslint-disable-next-line
            >
                {t('home_page__audit_events')}
            </Button>
            <Button
              className="create-btn--plus"
              icon={<Icon url={PlusIcon} />}
              onClick={() => history.push('/create')}
              // eslint-disable-next-line
            >
                {t('home_page__create_backup')}
            </Button>
        </>
    ), [t]);

    return (
        <>
            <section className={`services-page--header ${backup.subscriptions.length ? 'mb-20' : ''}`}>
                <h2>{t('home_page__my_data')}</h2>
                {
                    // eslint-disable-next-line no-nested-ternary
                    backup.subscriptions.length
                        ? (
                            isMobile ? HeaderActions : <div>{HeaderActions}</div>
                        )
                        : <span />
                }
            </section>
            <section className="services-page--wrapper">
                {
                    isLoading
                        ? <Spin className="spinner-centerize" />
                        : backup.subscriptions.map(({
                              lastSyncDate,
                              lastSyncStatus,
                              active,
                              service,
                              serviceId,
                              serviceIdAlias,
                              id,
                              loading,
                              subscriptionId,
                        }) => (
                            <ServiceCard
                              key={serviceId}
                              id={id}
                              lastSyncDate={lastSyncDate}
                              lastSyncStatus={lastSyncStatus}
                              active={active}
                              service={service}
                              serviceId={serviceId}
                              serviceIdAlias={serviceIdAlias}
                              loading={loading}
                              toggleSwitch={handleToggleSwitch}
                              restore={handleRestoreData}
                              remove={setShowRemoveModal}
                              subscriptionId={subscriptionId}
                            />
                        ))
                }
                {
                    !isLoading && backup.subscriptions.length === 0 && (
                        <div className="empty-subscription-text">
                            <h5>{t('home_page__welcome_to_ransomproof')}</h5>
                            <p>{t('home_page__few_steps_away_from_protecting')}</p>
                            <ul>
                                <li>{t('home_page__free_forever_backups')}</li>
                                <li>{t('home_page__one_time_setup_process')}</li>
                            </ul>
                            <p>{t('home_page__click_create_backup')}</p>
                        </div>
                    )
                }
                {
                    !isLoading && !backup.subscriptions.length && (
                        <div className="create-backup--wrapper">
                            <Button
                              className="create-btn--plus"
                              icon={<Icon url={PlusIcon} />}
                              onClick={() => history.push('/create')}
                            >
                                {t('home_page__create_backup')}
                            </Button>
                        </div>
                    )
                }
            </section>
            {/* Modal remove subscription */}
            <Modal
              visible={!!showRemoveModal}
              title=""
              closable
              saveText={t('Delete')}
              onCancel={() => setShowRemoveModal(false)}
              onSave={() => handleRemoveSubscription(showRemoveModal)}
              destroyOnClose
              className="remove-modal"
              // eslint-disable-next-line
             >
                <div className="remove-modal--wrapper">
                    <h4>{t('home_page__sure_want_to_delete')} {typeof showRemoveModal === 'boolean' ? '' : `"${showRemoveModal?.serviceIdAlias}"`}?</h4>
                    <p>{t('home_page__service_will_be_deleted_immediately')}</p>
                </div>
            </Modal>
        </>
    );
}

export default Home;

Home.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};
