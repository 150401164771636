export const initialFilter = {
    search: null,
    isDeep: false,
    date: {
        value: '',
        code: '',
        dateFrom: null,
        dateTo: null,
    },
};
