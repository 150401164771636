import React, { FC, useMemo } from 'react';
// components
import { Progress } from 'antd';
import Document from './Document';
// types
import { ProgressChartProps } from './types';
// styles
import './ProgressChart.scss';

const ProgressChart: FC<ProgressChartProps> = ({ status, colors }) => {
    // eslint-disable-next-line react/no-unstable-nested-components
    const format = useMemo(() => () => <Document />, []);

    const getPercent = () => {
        if (status.includes('/')) {
            const [d1, d2] = status.split('/');
            return (+d1 * 100) / +d2;
        }
        if (status === 'done') return 100;

        return 0;
    };

    return (
        <div className="progress-component--wrapper">
            <Progress
              strokeLinecap="round"
              type="circle"
              percent={getPercent()}
              width={48}
              format={format}
              strokeColor={colors[status] ?? '#FFEF11'}
            />
        </div>
    );
};

export default ProgressChart;
