import { Key } from 'react';
import { getCookie } from '../helpers/util';

export const restoreService = {
    getFolders, zipSubfolders, getSubfolders, getSubfolderFile, removeSubfolderFile,
};

interface getSubfolderProps {
    subscriptionId: string,
    page: number,
    dateFrom: string | null
    dateTo: string | null
    search: string | null
}

interface zipSubfoldersProps {
    id: string,
    dateFrom: string | null,
    dateTo: string | null,
    mode: string | null,
    search: string | null,
    keys: Array<Key> | null,
}

function getFolders(id: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/data/folder?id=${id}`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function zipSubfolders(props: zipSubfoldersProps) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/data/zip`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify(props),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            if (response.status === 200) {
                return { message: 'Selected files are archiving' };
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function getSubfolders(props: getSubfolderProps) {
    const payload = {
        size: 10,
        ...props,
    };

    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/data/folder/objects`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function getSubfolderFile(subscriptionId: string, path: string, filename: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/data/folder/objects/get`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getCookie('token')}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ subscriptionId, key: path }),
        },
    )
        .then(async (response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            // @ts-ignore
            link.parentNode.removeChild(link);
        });
}

function removeSubfolderFile(id: string, path: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/data/folder/objects/delete`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getCookie('token')}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ id, key: path }),
        },
    )
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }
        });
}
