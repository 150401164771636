import React, {
    useState, useMemo, useCallback, SyntheticEvent,
} from 'react';
// utils
import { useTranslation } from 'react-i18next';
// assets
import './LanguageSelector.scss';

function LanguageSelector() {
    const { i18n } = useTranslation();

    const current = localStorage.getItem('lang') || 'en' as string;
    const [state, setState] = useState(current);

    const handleChange = useCallback((code: string) => {
        setState(code);
        localStorage.setItem('lang', code);
        i18n.changeLanguage(code);
    }, []);

    const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const options = useMemo(() => ([
        { code: 'ru', title: 'Ru' },
        { code: 'en', title: 'En' },
    ]), []);

    return (
        <div
          className="language-selector--wrapper"
          tabIndex={0}
          role="button"
          onKeyPress={handleClick}
          onClick={handleClick}
          // eslint-disable-next-line
        >
            {
                (
                    options.map(({ code, title }) => (
                        <div
                          key={code}
                          className={`language-selector ${state === code ? 'active' : ''}`}
                          tabIndex={0}
                          role="button"
                          onKeyPress={() => handleChange(code)}
                          onClick={() => handleChange(code)}
                        >
                            {title}
                        </div>
                    ))
                )
            }
        </div>

    );
}

export default LanguageSelector;

LanguageSelector.defaultProps = {
    isDropdown: true,
};
