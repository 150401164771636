import { getCookie } from '../helpers/util';

export const downloadsService = {
    get,
    download,
    refresh,
};

interface getDownloadFilesProps {
    id?: string;
    page: number;
}

function get(props: getDownloadFilesProps) {
    const payload = {
        size: 10,
        ...props,
    };

    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/data/zip-downloads`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function refresh(id: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/data/zip-download-get`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ id }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function download(subscriptionId: string, key: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/data/folder/objects/get`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ subscriptionId, key }),
    }).then(async (response) => {
        if (response.status === 401) {
            throw Error('401');
        }
        if (response.status !== 200) {
            throw Error();
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', key);
        document.body.appendChild(link);
        link.click();
        // @ts-ignore
        link.parentNode.removeChild(link);
    });
}
