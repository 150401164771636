import React, {
    SyntheticEvent, useCallback, useContext,
} from 'react';
import { Switch } from 'antd';
import { BaseDataContext, IContextState } from '../../contexts/BaseContext';
import './ThemeSwitch.scss';

function ThemeSwitch() {
    const { contextState, setContextState } = useContext(BaseDataContext);

    const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const handleChange = useCallback((checked: boolean) => {
        // @ts-ignore
        setContextState((prev: IContextState) => ({
            ...prev,
            theme: checked ? 'light' : 'dark',
        }));
        localStorage.setItem('theme', checked ? 'light' : 'dark');
    }, []);

    return (
        <div
          className={`theme-switch--wrapper ${contextState.theme === 'light' ? 'light' : 'dark'}`}
          tabIndex={0}
          role="button"
          onClick={handleClick}
          onKeyPress={handleClick}
          // eslint-disable-next-line
        >
            <Switch
              defaultChecked={contextState.theme === 'light'}
              onChange={handleChange}
            />
        </div>
    );
}

export default ThemeSwitch;
