import React, { FC, useCallback } from 'react';
// utils
import { useTranslation } from 'react-i18next';
// components
import { Pagination as BasePagination } from 'antd';
import { BackwardIcon, ForwardIcon } from './Icons';
import Button from '../Button';
// types
import { PaginationProps } from './types';
// styles
import './Pagination.scss';

const Pagination: FC<PaginationProps> = ({ page, total, onPageChange }) => {
    const { t } = useTranslation();

    const itemRender = useCallback((_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <div className="prev-btn--container">
                    <BackwardIcon color={page === 1 ? '#B3B8C7' : '#0EC7D9'} />
                    <Button type="link" disabled={page === 1}>{t('Previous')}</Button>
                </div>
            );
        }
        if (type === 'next') {
            const totalPages = Math.ceil(total / 10);
            return (
                <div className="prev-btn--container">
                    <Button type="link" disabled={page === totalPages}>{t('Next')}</Button>
                    <ForwardIcon color={page === totalPages ? '#B3B8C7' : '#0EC7D9'} />
                </div>
            );
        }
        return originalElement;
    }, [page]);

    return (
        <BasePagination
          itemRender={itemRender}
          current={page}
          total={total}
          onChange={onPageChange}
          className="custom-pagination"
          hideOnSinglePage
          pageSize={10}
          showSizeChanger={false}
        />
    );
};

export default Pagination;
