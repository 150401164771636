import React, {
    useState, useEffect, useMemo, useCallback, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
// components
import { Pagination, Badge } from 'antd';
import Table from '../../components/Table';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import AuditOfActionCard from './AuditOfActionCard';
import { BackIcon } from '../Service/Service';
// types
import { IIcon } from '../Service';
// services
import { commonServices } from '../../services';
// utils
import { capitalizeFirstLetter } from '../../helpers/util';
import { BaseDataContext } from '../../contexts/BaseContext';
// assets
import ShopifyIcon from '../../assets/images/svg/shopify.svg';
import QuickbooksIcon from '../../assets/images/svg/quickbooks.svg';
import SalesforceIcon from '../../assets/images/svg/salesforce.svg';
import SlackIcon from '../../assets/images/svg/slack.svg';
import HubspotIcon from '../../assets/images/svg/hubspot.svg';
import ErrorIcon from '../../assets/images/svg/error-2.svg';
import TickIcon from '../../assets/images/svg/tick.svg';
import './AuditOfActions.scss';

interface IProps {
    id: string,
    status: 'active' | 'inactive' | 'removed',
    serviceId: string,
    serviceIdAlias: string,
    serviceCode: string
}

export function BackwardIcon({ color }: { color: string }) {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2111 6.00003L5.85753 3.35359L5.15043 2.64648L1.29688 6.50004L5.15043 10.3536L5.85753 9.64648L3.21108 7.00003H10.5005V6.00003H3.2111Z" fill={color} />
        </svg>
    );
}

export function ForwardIcon({ color }: { color: string }) {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.79286 7.00003H1.5V6.00003H8.79285L6.14641 3.35359L6.85352 2.64648L10.7071 6.50004L6.85352 10.3536L6.14641 9.64648L8.79286 7.00003Z" fill={color} />
        </svg>
    );
}

function AuditOfActions() {
    const { t } = useTranslation();
    const history = useHistory();
    const { state }: { state: undefined | { id: string } } = useLocation();
    const isMobile = window.innerWidth <= 480;

    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState({
        selected: '',
        list: [],
    });
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 0,
    });

    const { contextState } = useContext(BaseDataContext);

    const handleChangePage = useCallback((page, size) => {
        getEvents(page, size);
    }, [subscriptions.selected]);

    const handleChange = useCallback((value: string) => {
        setSubscriptions((prev) => ({
            ...prev,
            selected: value,
        }));
    }, [subscriptions]);

    const handleClickBack = useCallback(() => {
        history.push('/');
    }, []);

    const itemRender = useCallback((_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <div className="prev-btn--container">
                    <BackwardIcon color={pagination.current === 1 ? '#B3B8C7' : '#0EC7D9'} />
                    <Button type="link" disabled={pagination.current === 1}>{t('Previous')}</Button>
                </div>
            );
        }
        if (type === 'next') {
            const totalPages = Math.ceil(pagination.total / 10);
            return (
                <div className="prev-btn--container">
                    <Button type="link" disabled={pagination.current === totalPages}>{t('Next')}</Button>
                    <ForwardIcon color={pagination.current === totalPages ? '#B3B8C7' : '#0EC7D9'} />
                </div>
            );
        }
        return originalElement;
    }, [pagination.current, subscriptions.selected]);

    const icons: IIcon = useMemo(() => ({
        shopify: ShopifyIcon,
        quickbooks: QuickbooksIcon,
        salesforce: SalesforceIcon,
        slack: SlackIcon,
        hubspot: HubspotIcon,
    }), []);

    const columns = useMemo(() => ([
        {
            title: t('Event'),
            dataIndex: 'eventType',
            key: 'eventType',
            render: (eventType: string) => (
                <span className="file-name-wrapper">{ eventType?.split('_').join(' ') }</span>
            ),
        },
        {
            title: t('Status'),
            dataIndex: 'isError',
            key: 'isError',
            render: (isError: string) => (
                <span className={`status-component ${isError === 'failed' ? 'fail' : ''}`}>
                    <Icon url={isError === 'failed' ? ErrorIcon : TickIcon} width={24} />
                    { isError === 'failed' ? t('Fail') : t('Success') }
                </span>
            ),
        },
        {
            title: t('Date_and_time'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <span className="file-name-wrapper">{ moment(new Date(createdAt)).format('YYYY-MM-DD HH:mm:ss') }</span>
            ),
        },
    ]), [t]);

    const colorMap = useMemo(() => ({
        active: 'green',
        inactive: 'yellow',
        removed: 'red',
    }), []);

    useEffect(() => {
        getEvents(1, 10);
    }, [subscriptions.selected]);

    useEffect(() => {
        if (state?.id) {
            setSubscriptions({
                selected: state.id,
                list: [],
            });
        }
        getSubscriptions();
    }, [state]);

    async function getSubscriptions(): Promise<void> {
        try {
            setIsLoading(true);
            const { content } = await commonServices.getSubscriptionsList();
            // eslint-disable-next-line max-len
            const result = content.map(({
                id, status, serviceId, serviceIdAlias, serviceCode,
            }: IProps) => ({
                code: id,
                title: (
                    <span>
                        <Badge color={colorMap[status]} />
                        <Icon url={icons[serviceCode]} width={24} />
                        {`${capitalizeFirstLetter(serviceCode)}: ${serviceIdAlias || serviceId}`}
                    </span>
                ),
            }));
            setSubscriptions((prev) => ({
                ...prev,
                list: result,
            }));
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function getEvents(page: number, size: number): Promise<void> {
        if (!subscriptions.selected) return;
        try {
            setIsLoading(true);
            // eslint-disable-next-line max-len
            const { content, total } = await commonServices.getAuditOfActions(subscriptions.selected, page, size);
            setData(content);
            setPagination({
                total,
                current: page,
            });
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <section className="services-page--header create-service">
                <Button
                  type="link"
                  onClick={handleClickBack}
                >
                    <BackIcon color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                </Button>
                <h2>{t('audit_page__audit_events')}</h2>
            </section>
            <section className="audit_of_actions-page--container">
                <h5>{t('audit_page__please_select_source_type')}</h5>
                <div className="pad">
                    <Select
                      onChange={handleChange}
                      value={subscriptions.selected}
                      options={subscriptions.list}
                      placeholder={`${t('audit_page__source_types')}...`}
                    />
                </div>
                {
                    subscriptions.selected && (
                        <>
                            {
                                isMobile
                                    ? (
                                        data.map((item) => (
                                            <AuditOfActionCard {...item} />
                                        ))
                                    )
                                    : (
                                        <Table
                                          rowKey="id"
                                          columns={columns}
                                          data={data}
                                          pagination={false}
                                          loading={isLoading}
                                          bordered={false}
                                          className="restore-file--table"
                                        />
                                    )
                            }
                            <Pagination
                              itemRender={itemRender}
                              current={pagination.current}
                              total={pagination.total}
                              onChange={handleChangePage}
                              className="custom-pagination"
                              hideOnSinglePage
                              pageSize={10}
                              showSizeChanger={false}
                            />
                        </>
                    )
                }
            </section>
        </>
    );
}

export default AuditOfActions;
