import { getCookie } from '../helpers/util';

export const providedServicesService = { get, getByCode, getDefinitionById };

function get() {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/provided-service/list`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function getByCode(code: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/source-definition/find?serviceCode=${code}`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function getDefinitionById(id: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/source-definition-specification?sourceDefinitionId=${id}`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}
