import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
// components
import { Spin, Checkbox } from 'antd';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
// types
import { IFile } from '../RestoreFile/types';
import { IProps } from './types';
// utils
import { formatBytes, capitalizeFirstLetter } from '../../../helpers/util';
// assets
import DownloadIcon from '../../../assets/images/svg/download-2.svg';
import './RestoreFileCard.scss';

function RestoreFileCard({
    items, isSelect, selectedRowKeys, onSelect, onDownload,
}: IProps) {
    const [loading, setLoading] = useState(false);
    // const [selectionItems, setSelectionItems] = useState([]);

    const handleDownload = async (file: IFile) => {
        setLoading(true);
        try {
            await onDownload(file);
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = useCallback((event: CheckboxChangeEvent) => {
        const { name, checked } = event.target;

        if (checked && name) {
            onSelect([...selectedRowKeys, name]);
        }
        if (!checked && name) {
            const newSelectionList = selectedRowKeys.filter((k) => k !== name);
            onSelect(newSelectionList);
        }
    }, [selectedRowKeys]);

    return (
        <>
            {items.map((item) => (
                <div className="files-card--wrapper">
                    <div>
                        <div>
                            {isSelect && (
                                <Checkbox
                                  name={item.storageKey}
                                  onChange={handleSelect}
                                  checked={selectedRowKeys.includes(item.storageKey)}
                                />
                            )}
                            <span>{ capitalizeFirstLetter(item.folder) }</span>
                        </div>
                        {
                            loading
                                ? <Spin size="small" />
                                : (
                                    <Button type="link" onClick={() => handleDownload(item)}>
                                        <Icon url={DownloadIcon} width={24} />
                                    </Button>
                                )
                        }
                    </div>
                    <div>
                        <span>{ item.storageKey.substr(item.storageKey.lastIndexOf('/') + 1) }</span>
                    </div>
                    <div>
                        <span>{ moment(item.jobDate).utc().format('MM/DD/YYYY HH:mm') }</span>
                        <span>{ formatBytes(item.size) }</span>
                    </div>
                </div>
            ))}
        </>
    );
}

export default RestoreFileCard;
