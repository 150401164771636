import { IIcon } from '../../Service';

import ShopifyIcon from '../../../assets/images/svg/shopify.svg';
import QuickbooksIcon from '../../../assets/images/svg/quickbooks.svg';
import SalesforceIcon from '../../../assets/images/svg/salesforce.svg';
import SlackIcon from '../../../assets/images/svg/slack.svg';
import HubspotIcon from '../../../assets/images/svg/hubspot.svg';

const icons: IIcon = {
    shopify: ShopifyIcon,
    quickbooks: QuickbooksIcon,
    salesforce: SalesforceIcon,
    slack: SlackIcon,
    hubspot: HubspotIcon,
};

export { icons };
