import React, {
    useState, useEffect, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from '../Button';
// types
import { inputStatus } from '../../types/common';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
// assets
import './Slack.scss';

interface IAuth20Configs {
    option_title: string,
    client_id: string,
    access_token: string,
    client_secret: string,
    refresh_token: string,
}

interface ISlackState {
    start_date: string,
    join_channels: boolean,
    lookback_window: number,
    channel_filter: [],
    credentials: IAuth20Configs
}

interface ISlackProps {
    initial?: ISlackState
    id?: string
    subscriptionId?: string;
}

function Slack({ initial, id, subscriptionId }: ISlackProps) {
    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);
    const { backup } = contextState;

    const [valid, setValid] = useState<inputStatus>();

    useEffect(() => {
        const slackService = backup.subscriptions.filter((item) => (
            item.service === 'slack' && item?.serviceId === initial?.credentials?.client_id
        ));
        if (slackService.length) {
            setValid('error');
            return;
        }
        setValid('success');
    }, [backup.subscriptions]);

    const handleClickSignIn = () => {
        let host = `${window.location.origin}/signin/slack`;
        const { REACT_APP_SLACK_CLIENT_ID, REACT_APP_SLACK_SCOPES } = process.env;
        // change redirect url if it's resubscribe flow
        if (id && subscriptionId) {
            const slackService = backup.subscriptions.filter((item) => (
                item.id === id
            ));
            if (!slackService.length) return;
            host = `${window.location.origin}/resubscribe/slack`;
            // @ts-ignore
            localStorage.setItem('serviceName', slackService[0].serviceIdAlias);
            localStorage.setItem('serviceID', subscriptionId);
        }
        window.location.replace(`https://slack.com/oauth/v2/authorize?redirect_uri=${host}&client_id=${REACT_APP_SLACK_CLIENT_ID}&scope=${REACT_APP_SLACK_SCOPES}&user_scope=`);
    };

    return (
        <>
            <div className="selected-service-header">
                <p className="definition mb-0">
                    Here will be definition...
                </p>
            </div>
            <div className="selected-service-body">
                {
                  valid === 'error' && (
                  <p>{t('multiple_instance')} Slack.</p>
                  )
                }
            </div>
            <div className="select-service-footer">
                <Button
                  onClick={handleClickSignIn}
                  className="create-btn"
                >
                    {t('Sign_in_via')} Slack
                </Button>
            </div>
        </>
    );
}

export default Slack;

Slack.defaultProps = {
    initial: undefined,
    id: '',
    subscriptionId: undefined,
};
