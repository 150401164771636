import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import { Col, Row } from 'antd';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Notification from '../../components/Notification';
// utils
import { commonServices } from '../../services';
// Assets
import SendIcon from '../../assets/images/svg/send.svg';
import './Decryption.scss';

function Decryption() {
    const { t } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const handleSendKeys = useCallback(async () => {
        setLoading(true);
        try {
            await commonServices.sendKeysByEmail();
            Notification({
                type: 'success',
                message: t('decryption_page__successfully_sent'),
                description: t('decryption_page__your_private_key'),
                duration: null,
            });
        } catch (e: any) {
            if (e.message === '401') {
                history.push('/login');
            }
            Notification({
                type: 'error',
                message: t('decryption_page__couldnt_send'),
                description: t(e.message),
                duration: null,
            });
        }
        setLoading(false);
    }, []);

    return (
        <section className="decryption-page">
            <Row>
                <Col span={24} className="decryption-page--header">
                    <h2>{t('Decryption')}</h2>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="decryption-page-info">
                    <div className="info-row">
                        <div className="info-left">
                            <p>{t('decryption_page__secret_key_and_passphrase')}</p>
                            <span>{t('decryption_page__please_click_send_btn')}</span>
                        </div>
                        <Button
                          loading={loading}
                          className="send-btn--icon"
                          icon={<Icon url={SendIcon} width={18} />}
                          onClick={handleSendKeys}
                        >
                            {t('Send')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Decryption;
