import React from 'react';
import { useTranslation } from 'react-i18next';
import './SubscribeAfterRedirect.scss';

function SubscribedMessage({ name }: { name: string }) {
    const { t } = useTranslation();
    return (
        <div className="subscribed-message">
            <h5>{t('subscribe_page__congrats')}</h5>
            <p>
                {t('subscribe_page__you_have_successfully_setup')}
                {name}
                {t('subscribe_page__daily_backup')}.
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                {t('subscribe_page__you_can_access_data')}.
            </p>
            <h5>{t('subscribe_page__what_to_expect')}</h5>
            <p>
                {t('subscribe_page__data_will_be_backed_up')}
                {t('subscribe_page__only_email_you_specific_service')}
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                {t('subscribe_page__sit_back_and_relax')}
            </p>
        </div>
    );
}

export default SubscribedMessage;
