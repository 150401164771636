import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDataContext } from '../../contexts/BaseContext';

function AccountBlock() {
    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);

    return (
        <div className="account-block-wrapper">
            <b>{t('navbar_component__profile')}</b>
            <span title={contextState.user.email ?? ''}>{contextState.user.email}</span>
        </div>
    );
}

export default AccountBlock;
