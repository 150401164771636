import React, { forwardRef } from 'react';

interface HelpProps {
    color: string;
    className: string;
}

const Help = forwardRef<SVGSVGElement, HelpProps>((props, ref) => (
    <svg ref={ref} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill={props.color} />
        <path d="M11.0936 13.8179H12.4849V13.4049C12.4849 13.1702 12.5162 12.9693 12.5786 12.8023C12.6455 12.6308 12.7592 12.4705 12.9197 12.3216C13.0803 12.1681 13.3032 12.0056 13.5886 11.8341C14.0346 11.5678 14.3802 11.2473 14.6254 10.8727C14.8751 10.4936 15 10.0445 15 9.52539V9.51185C15 9.02889 14.8751 8.60009 14.6254 8.22546C14.3802 7.84631 14.0346 7.54841 13.5886 7.33175C13.1472 7.11058 12.6299 7 12.0368 7C11.3946 7 10.8506 7.11961 10.4047 7.35884C9.95875 7.59355 9.61761 7.91627 9.38127 8.32701C9.14939 8.73324 9.0223 9.18912 9 9.69465V9.70819L10.3846 9.70142L10.398 9.69465C10.4158 9.40126 10.4916 9.14624 10.6254 8.92959C10.7592 8.70842 10.9398 8.53916 11.1672 8.4218C11.3946 8.30445 11.6622 8.24577 11.9699 8.24577C12.2731 8.24577 12.5362 8.30445 12.7592 8.4218C12.9866 8.53464 13.1605 8.69036 13.2809 8.88896C13.4058 9.08756 13.4682 9.3155 13.4682 9.57278V9.58632C13.4682 9.81201 13.4326 10.0129 13.3612 10.1889C13.2943 10.3649 13.1806 10.5297 13.0201 10.6831C12.8595 10.8321 12.6455 10.9901 12.3779 11.1571C12.0747 11.3421 11.825 11.5385 11.6288 11.7461C11.4326 11.9492 11.2899 12.1749 11.2007 12.4232C11.1159 12.6714 11.078 12.9558 11.087 13.2762L11.0936 13.8179ZM11.8696 17C12.1817 17 12.4314 16.9075 12.6187 16.7224C12.806 16.5374 12.8997 16.2936 12.8997 15.9912C12.8997 15.6843 12.806 15.4383 12.6187 15.2532C12.4314 15.0636 12.1817 14.9689 11.8696 14.9689C11.5619 14.9689 11.3122 15.0636 11.1204 15.2532C10.9287 15.4383 10.8328 15.6843 10.8328 15.9912C10.8328 16.2936 10.9287 16.5374 11.1204 16.7224C11.3122 16.9075 11.5619 17 11.8696 17Z" fill={props.color} />
    </svg>
));

export default Help;
