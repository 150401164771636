/* eslint-disable no-unused-vars */
import React, {
    useState, useCallback, useRef, useEffect, useMemo, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { Form, Input } from 'antd';
// import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Notification from '../../components/Notification';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
import { deleteCookie } from '../../helpers/util';
import { commonServices } from '../../services';
import doc from '../../assets/docs/User Guide Beta v1.0.pdf';

import ArrowDownIcon from '../../assets/images/svg/arrow-down-2.svg';
import Icon from '../../components/Icon';
import 'react-multi-email/style.css';
import './Support.scss';

interface FormTypes {
    cc: Array<string>,
    subject: string,
    body: string,
}

function Support() {
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState('');

    const { contextState } = useContext(BaseDataContext);

    const isMounted = useRef(true);

    const getLabel = useCallback((
        email: string,
        index: number,
        removeEmail: (i: number) => void,
    ) => (
        <div data-tag key={index}>
            {email}
            <span
              data-tag-handle
              role="button"
              tabIndex={0}
              onClick={() => removeEmail(index)}
              onKeyDown={() => removeEmail(index)}
            >
                ×
            </span>
        </div>
    ), []);

    const options = useMemo(() => ([
        { code: 'quickbooks', title: 'QuickBooks' },
        { code: 'shopify', title: 'Shopify' },
        { code: 'salesforce', title: 'Salesforce' },
        { code: 'general', title: t('General_Support_Request') },
        { code: 'other', title: t('Other') },
    ]), [t]);

    const onFinish = useCallback((values: any) => {
        const { subject, body } = values;

        setIsLoading(true);
        commonServices.sendEmailToSupport(subject, body)
            .then(() => {
                Notification({
                    type: 'success',
                    message: `
                        ${t('support_page__message_sent')}: ${contextState?.user?.email}
                    `,
                    description: '',
                    duration: 5,
                });
                form.resetFields();
            })
            .catch((error: any) => {
                if (error.message === '401') {
                    deleteCookie('token');
                    history.push('/login');
                }
                if (!isMounted.current) return;
                Notification({
                    type: 'error',
                    message: t('support_page__message_send_failed'),
                    description: t(error.message),
                    duration: null,
                });
            })
            .finally(() => { setIsLoading(false); });
    }, []);

    useEffect(() => () => {
        isMounted.current = false;
    }, []);

    return (
        <>
            <section className="services-page--header">
                <h2>{t('support_page__support')}</h2>
            </section>
            <section className="services-page--wrapper support-page--wrapper">
                {/* <div className="download-user-guide--btn"> */}
                {/*    <Icon url={ArrowDownIcon} width={24} /> */}
                {/*    <a href={doc} download>Download User Guide</a> */}
                {/* </div> */}
                <p className="definition">{t('support_page__if_you_have_question')}</p>
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  form={form}
                  autoComplete="off"
                  className="support-page--form login-form"
                  requiredMark={false}
                >
                    {/* <Form.Item */}
                    {/*  name="cc" */}
                    {/*  className="custom-input custom-input--text multi-emails-input" */}
                    {/* > */}
                    {/*    <ReactMultiEmail */}
                    {/*      validateEmail={(email) => isEmail(email)} */}
                    {/*      getLabel={getLabel} */}
                    {/*      placeholder="CC" */}
                    {/*    /> */}
                    {/* </Form.Item> */}

                    <Form.Item
                      name="subject"
                      rules={[{ required: true, message: t('support_page__please_choose_category') }]}
                    >
                        <Select
                          options={options}
                          value={state}
                          onChange={(code: string) => setState(code)}
                          dropdownClassName="support-dropdown"
                        />
                    </Form.Item>

                    <Form.Item
                      name="body"
                      rules={[{ required: true, message: t('support_page__please_describe_your_inquiry') }]}
                    >
                        <Input.TextArea
                          // showCount
                          rows={4}
                          maxLength={300}
                          placeholder={t('Message')}
                        />
                    </Form.Item>

                    <Form.Item className="submit-btn">
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {t('support_page__send_message')}
                        </Button>
                    </Form.Item>
                </Form>

            </section>
        </>
    );
}

export default Support;
