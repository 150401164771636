import React from 'react';
import { notification } from 'antd';
// Assets
// import SuccessIcon from '../../assets/images/svg/success.svg';
// import ErrorIcon from '../../assets/images/svg/error.svg';
import CloseIcon from '../../assets/images/svg/close.svg';
import './Notification.scss';

interface IProps {
    type: 'success' | 'error' | 'warning',
    message: string,
    description: string | React.ReactNode,
    duration: number | null,
}

function Icon({ url }: { url: string }) {
    return (
        <img src={url} alt="icon" />
    );
}

function Notification({
    type, message, description, duration,
}: IProps) {
    const colorsMap = {
        success: '#73BF16',
        error: '#FF403D',
        warning: '#FFCC00',
    };

    notification.open({
        duration,
        placement: 'bottomRight',
        message,
        description,
        className: 'custom-notification--container',
        // icon: <Icon url={type === 'success' ? SuccessIcon : ErrorIcon} />,
        closeIcon: <Icon url={CloseIcon} />,
        style: {
            borderLeft: `8px solid ${colorsMap[type]}`,
        },
    });
}

export default Notification;
