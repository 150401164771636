import React, {
    useState, useEffect, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Form, Input, Tooltip } from 'antd';
import Screenshot from './Screenshot';
import Help from './Help';
// import Select from '../Select';
import Button from '../Button';
// types
import { inputStatus } from '../../types/common';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
import { generateString, valueDefined } from '../../helpers/util';
// assets
import './Shopify.scss';

interface IAuth20Configs {
    auth_method: string,
    client_secret: string,
    access_token: string,
    client_id: string,
}

interface IShopifyProps {
    id?: string,
    subscriptionId?: string,
    initial?: {
        shop: string,
        start_date: string,
        credentials: IAuth20Configs
    },
}

function Shopify({ id, subscriptionId, initial }: IShopifyProps) {
    const [state, setState] = useState({
        shop: '',
    });
    const [valid, setValid] = useState<inputStatus>();

    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);
    const { backup } = contextState;

    const tooltipText = useMemo(() => ({
        shop: t('shopify_component__shop'),
        access: t('shopify_component__access'),
        id: t('shopify_component__id'),
        secret: t('shopify_component__secret'),
    }), [t]);

    useEffect(() => {
        const shopifyService = backup.subscriptions.filter((item) => (
            item.service === 'shopify' && item?.serviceId === state.shop
        ));
        if (shopifyService.length && !initial) {
            setValid('error');
            return;
        }
        setValid('success');
    }, [state.shop]);

    useEffect(() => {
        if (!initial) return;
        const { shop } = initial;
        setState({ shop });
    }, [initial]);

    const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleClickSignIn = () => {
        let host = `${window.location.origin}/signin/shopify`;
        // change redirect url if it's resubscribe flow
        if (id && subscriptionId) {
            host = `${window.location.origin}/resubscribe/shopify`;
            localStorage.setItem('serviceID', subscriptionId);
        }
        // Generate unique key for request
        const uniqueKey = generateString();
        // Store it in localstorage for comparing on response
        localStorage.setItem('unique', uniqueKey);
        const { REACT_APP_SHOPIFY_CLIENT_ID, REACT_APP_SHOPIFY_SCOPES } = process.env;
        window.location.replace(`https://${state.shop}.myshopify.com/admin/oauth/authorize?client_id=${REACT_APP_SHOPIFY_CLIENT_ID}&scope=${REACT_APP_SHOPIFY_SCOPES}&redirect_uri=${host}&state=${uniqueKey}&grant_options[]=value`);
    };

    return (
        <>
            <div className="selected-service-header">
                <p className="definition mb-0">
                    {t('shopify_component__definition')}
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span>"https://my-store-name.myshopify.com"</span>
                    {' '}
                    {t('shopify_component__definition_2')}
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span>"my-store-name".</span>
                </p>
                <Screenshot className="definition-image" color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                {/* <img className="definition-image" src={ScreenshotIcon} alt="screenshot" /> */}
                <p className="definition">
                    {t('shopify_component__definition_3')}
                </p>
                <h5>{t('shopify_component__store_name')}</h5>
            </div>
            <div className="selected-service-body">
                <Form.Item
                  rules={[{ required: true, message: t('shopify_component__fill_store_name') }]}
                  validateStatus={valid}
                  help={valid !== 'error' ? '' : t('shopify_component__subscription_already_exist')}
                    // eslint-disable-next-line
                >
                    <Input
                      name="shop"
                      value={state.shop}
                      disabled={!!initial}
                      onChange={handleChangeState}
                      placeholder={`${t('shopify_component__type_shop_name')} ...`}
                      className="service-name--input"
                      suffix={(
                          <Tooltip
                            title={tooltipText.shop}
                            overlayClassName={`${contextState.theme === 'light' ? 'light' : 'dark'} input-tooltip-style`}
                            placement="right"
                          >
                              <span>
                                  <Help color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} className="" />
                              </span>
                          </Tooltip>
                        )}
                    />
                </Form.Item>

                <Button
                  onClick={handleClickSignIn}
                  className="create-btn"
                  disabled={!(valueDefined(state.shop) && valid !== 'error')}
                  // eslint-disable-next-line
                >
                    {t('Sign_in_via')} Shopify
                </Button>
            </div>
        </>
    );
}

export default Shopify;

Shopify.defaultProps = {
    id: undefined,
    subscriptionId: undefined,
    initial: undefined,
};
