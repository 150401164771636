import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleTwoTone, EyeTwoTone } from '@ant-design/icons';
import { BaseDataContext } from '../../contexts/BaseContext';
import Notification from '../Notification';
import Button from '../Button';
import Icon from '../Icon';
// utils
import { authenticationService } from '../../services/authentication';
import PasswordIcon from '../../assets/images/svg/password.svg';

interface ICodeDeliveryDetails {
    AttributeName: string,
    DeliveryMedium: string,
    Destination: string,
}

interface IProps {
    email: string,
}

function BackIcon({ color }: { color: string }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.97533 4.94165L2.91699 9.99998L7.97533 15.0583" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.0836 10H3.05859" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

function EmailConfirmation({ email }: IProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const { contextState } = useContext(BaseDataContext);

    const onFinish = async (values: any) => {
        const { code } = values;
        setIsLoading(true);
        try {
            const response: any = await authenticationService.resendConfirm(email, code);
            const { status } = response;
            if (status === 'failed') throw Error(response.message);

            Notification({
                type: 'success',
                message: t('Success_message'),
                description: t('auth_page__congrats_successfully_confirmed'),
                duration: 2,
            });
            history.push('/login');
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: 2,
            });
            setIsLoading(false);
        }
    };

    const handleResend = async () => {
        try {
            const response: any = await authenticationService.resendConfirmCode(email);
            const { httpStatusCode } = response.$metadata;
            if (httpStatusCode !== 200) throw Error(response.message);

            const { CodeDeliveryDetails }: {
                CodeDeliveryDetails: ICodeDeliveryDetails
            } = response;
            Notification({
                type: 'success',
                message: t('Success_message'),
                description: `${t('auth_page__sent_an_email')}: ${CodeDeliveryDetails.Destination} ${t('auth_page__with_secret_code')}`,
                duration: 2,
            });
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: 2,
            });
        }
    };

    return (
        <>
            <div className="login_form--container">
                <Button
                  type="link"
                  size="small"
                  onClick={() => history.push('/login')}
                  className="go-back--btn"
                    // eslint-disable-next-line
                >
                    <BackIcon color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                    {t('Back')}
                </Button>
                <h2>{t('auth_page__check_email')}</h2>
                <p>{t('auth_page__confirm_your_registration')}</p>

                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  autoComplete="off"
                  className="login-form"
                    // eslint-disable-next-line
                >
                    <Form.Item
                      name="code"
                      rules={[{ required: true, message: t('auth_page__please_input_code') }]}
                      className="custom-input custom-input--password"
                    >
                        <Input.Password
                          prefix={<Icon url={PasswordIcon} />}
                          placeholder={t('auth_page__please_input_six_digit_code')}
                          // eslint-disable-next-line
                          iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor='#0EC7D9' /> : <EyeInvisibleTwoTone twoToneColor='#0EC7D9' />)}
                        />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {t('auth_page__confirm_signup')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <p className="switch-signup">
                {t('auth_page__didnt_get_code')}
                <Button
                  type="link"
                  size="small"
                  onClick={handleResend}
                >
                    {t('auth_page__resend_code')}
                </Button>
            </p>
        </>
    );
}

export default EmailConfirmation;
