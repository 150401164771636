import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Select as AntSelect } from 'antd';
import { SelectCommonPlacement } from 'antd/lib/_util/motion';
import { BaseDataContext } from '../../contexts/BaseContext';
import Icon from '../Icon';

import './Select.scss';

const { Option } = AntSelect;

interface IProps {
    // eslint-disable-next-line no-undef
    options: Array<{ code: string, title: string, icon?: string }>,
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string, option: any) => void,
    loading?: boolean,
    value: string,
    placeholder?: string,
    disabled?: boolean,
    className?: string,
    suffixIcon?: React.ReactNode,
    dropdownClassName?: string,
    placement?: SelectCommonPlacement,
}

function Select({
    options, onChange, value, placeholder, disabled,
    loading, className, suffixIcon, dropdownClassName, placement,
}: IProps) {
    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);

    return (
        <AntSelect
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder && t(placeholder)}
          className={`${className} custom-select`}
          size="large"
          loading={loading}
          dropdownClassName={`custom-select-dropdown ${dropdownClassName} ${contextState.theme === 'light' ? 'light' : 'dark'}`}
          suffixIcon={suffixIcon}
          placement={placement}
          // eslint-disable-next-line
        >
            {
                options.map((option) => (
                    <Option value={option.code} key={option.code}>
                        {
                            option.icon
                            ? <Icon url={option.icon} width={32} />
                            : ''
                        }
                        { option.title }
                    </Option>
                ))
            }
        </AntSelect>
    );
}

export default Select;

Select.defaultProps = {
    className: '',
    placeholder: 'Select_an_option',
    disabled: false,
    loading: false,
    suffixIcon: undefined,
    dropdownClassName: '',
    placement: 'bottomRight',
};
