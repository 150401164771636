import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TRANSLATIONS_EN from './locales/en/translation.json';
import TRANSLATIONS_RU from './locales/ru/translation.json';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            ru: {
                translation: TRANSLATIONS_RU,
            },
        },
    });

export default i18n;
