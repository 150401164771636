import React, {
    useEffect, useState, useCallback, useRef, useMemo, useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// components
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Notification from '../../components/Notification';
import Wrapper from '../../components/Wrapper';
import SelectService from './Steps/SelectService';
import Shopify from '../../components/Shopify';
import Quickbooks from '../../components/Quickbooks';
import Salesforce from '../../components/Salesforce';
import Slack from '../../components/Slack';
import Hubspot from '../../components/Hubspot';
// types
import {
    IService, IServiceData, // IData
} from './types';
// utils
import { servicesService } from '../../services';
import { BaseDataContext } from '../../contexts/BaseContext';
import {
    capitalizeFirstLetter, deleteCookie, flattenObject, valueDefined,
} from '../../helpers/util';
// assets
import SuccessIcon from '../../assets/images/svg/done.svg';
import './Service.scss';
import Github from '../../components/Github';

export function BackIcon({ color }: { color: string }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.97533 4.94165L2.91699 9.99998L7.97533 15.0583" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.0836 10H3.05859" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

// { onChange, data }: { onChange: Function, data: IData | null }
function Service() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const isMounted = useRef(true);

    const [service, setService] = useState<IService>({
        list: [],
        info: '',
        code: '',
    });
    const [initial, setInitial] = useState<IServiceData|null>(null);
    const [subscription, setSubscription] = useState({
        credentials: {},
        params: {
            code: '',
        },
    });
    const [loader, setLoader] = useState({
        show: false,
        text: '',
        icon: '',
        button: false,
    });

    const { contextState } = useContext(BaseDataContext);

    const components = useMemo(() => ({
        shopify: Shopify,
        quickbooks: Quickbooks,
        salesforce: Salesforce,
        slack: Slack,
        hubspot: Hubspot,
        github: Github,
    }), []);
    // @ts-ignore
    const SelectedComponent = useMemo(() => components[service.code], [service.code]);

    const handleSubmit = useCallback(() => {
        if (initial !== null) {
            handleRecheckCredentials();
        } else {
            handleCheckCredentials();
        }
    }, [initial, subscription]);

    const handleSelectService = useCallback((selected) => {
        setService(selected);
    }, []);

    const handleClickBack = useCallback(() => {
        if (service.code) {
            setService(((prev) => ({
                ...prev,
                code: '',
            })));
            return;
        }
        history.push('/');
    }, [service]);

    const handleChildChange = useCallback((childData) => {
        const defined = Object.values(flattenObject(childData))
            .filter((item) => !valueDefined(item));

        if (!defined.length) {
            const { code } = service;
            setSubscription({
                credentials: childData,
                // @ts-ignore
                params: { code },
            });
            // if (initial !== null) {
            //     setSelected((prev) => ({ ...prev, resub }));
            // }
        } else {
            setSubscription((prev) => ({
                ...prev,
                credentials: {},
            }));
        }
    }, [service, initial]);

    const handleCheckCredentials = async () => {
        const userId = localStorage.getItem('userId');
        const { params, credentials } = subscription;
        const { code } = params;
        if (!userId) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe(code, credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev, icon: SuccessIcon, text: `${t('Successfully_subscribed')}!`, button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? t('service_page__couldnt_subscribe') : errorMessage);
            }

            // @ts-ignore
        } catch (error: Error) {
            if (error.message === '401') {
                deleteCookie('token');
                history.push('/login');
            }
            if (!isMounted.current) return;
            setLoader((prev) => ({
                ...prev, show: false, text: '', icon: '',
            }));
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    const handleRecheckCredentials = async () => {
        // @ts-ignore
        const { name: serviceName, sourceId, connectionConfiguration } = initial;
        const config = {
            // @ts-ignore
            subscriptionId: location?.state?.service?.subscriptionId,
            sourceId,
            connectionConfiguration,
            name: serviceName,
        };

        try {
            setLoader({
                show: true,
                text: `${t('Resubscribing')}...`,
                icon: '',
                button: false,
            });

            const resubscribeResponse = await servicesService.resubscribe(config);
            if (!isMounted.current) return;
            if (resubscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev, icon: SuccessIcon, text: `${t('Successfully_resubscribed')}!`, button: true,
                }));
            } else {
                throw new Error(t('resubscribe_page__couldnt_resubscribe'));
            }
            // @ts-ignore
        } catch (error: Error) {
            if (error.message === '401') {
                deleteCookie('token');
                history.push('/login');
            }
            if (!isMounted.current) return;
            setLoader((prev) => ({
                ...prev, show: false, text: '', icon: '',
            }));
            Notification({
                type: 'error',
                message: t('Resubscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    // Handle if it's editing mode
    useEffect(() => {
        // @ts-ignore
        const { state } = location;
        async function getService() {
            // @ts-ignore
            if (!state?.service) return;
            setService((prev) => ({
                ...prev,
                // @ts-ignore
                code: state?.service.service || '',
            }));
            try {
                const newData = await servicesService.getServiceById(
                    // @ts-ignore
                    state?.service?.subscriptionId
                    || null,
                );
                if (!isMounted.current) return;
                setInitial(newData);
            } catch (e:any) {
                if (e.message === '401') {
                    history.push('/login');
                }
                if (!isMounted.current) return;
                console.error(e);
            }
        }
        getService();
    }, [location]);

    return (
        <>
            <section className="services-page--header create-service">
                <Button
                  type="link"
                  onClick={handleClickBack}
                >
                    <BackIcon color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                </Button>
                <h2>
                    {t('service_page__create_backup')}
                    {' '}
                    {service.code ? `- ${capitalizeFirstLetter(service.code)}` : ''}
                </h2>
            </section>
            <section className="services-page--wrapper create-service--wrapper">
                {
                    service.code === ''
                        ? <SelectService onContinue={handleSelectService} />
                        : (
                            // Below are specific source type's fields
                            <SelectedComponent
                              initial={initial?.connectionConfiguration}
                              onChange={handleChildChange}
                              onSubmit={handleSubmit}
                              // @ts-ignore
                              id={location?.state?.service?.id}
                              // @ts-ignore
                              subscriptionId={location?.state?.service?.subscriptionId}
                              // @ts-ignore
                              alias={location?.state?.service?.serviceIdAlias}
                            />
                        )
                }
            </section>

            {
                loader.show
                    ? (
                        <Wrapper>
                            <div className="check-loader--container">
                                {
                                    loader.icon
                                        ? <Icon url={loader.icon} width={80} />
                                        : <span />
                                }
                                <span>{ loader.text }</span>
                                {
                                    loader.button
                                        ? (
                                            <Button
                                              onClick={() => history.push('/')}
                                            >
                                                {t('Close')}
                                            </Button>
                                        )
                                        : <span />
                                }
                            </div>
                        </Wrapper>
                    )
                    : <span />
            }
        </>
    );
}

export default Service;
