import React from 'react';
import { Button as AntButton } from 'antd';

import './Button.scss';

declare const ButtonTypes: ['default', 'primary', 'ghost', 'dashed', 'link', 'text'];
export declare type ButtonType = typeof ButtonTypes[number];

declare const ButtonHTMLTypes: ['submit', 'button', 'reset'];
export declare type ButtonHTMLType = typeof ButtonHTMLTypes[number];

interface IProps {
    children?: React.ReactNode,
    type?: ButtonType,
    htmlType?: ButtonHTMLType,
    className?: string,
    onClick?: Function,
    size?: string,
    icon?: React.ReactNode,
    disabled?: boolean,
    loading?: boolean,
    style?: object,
    name?: string,
    title?: string,
}

function Button({ children, ...props }: IProps) {
    return (
        // @ts-ignore
        <AntButton {...props}>
            { children }
        </AntButton>
    );
}

export default Button;

Button.defaultProps = {
    type: undefined,
    htmlType: undefined,
    className: undefined,
    onClick: () => null,
    size: undefined,
    icon: undefined,
    disabled: false,
    loading: false,
    children: undefined,
    style: undefined,
    name: undefined,
    title: undefined,
};
