import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleTwoTone, EyeTwoTone } from '@ant-design/icons';
import { BaseDataContext } from '../../contexts/BaseContext';
import Notification from '../Notification';
import Button from '../Button';
import Icon from '../Icon';
// utils
import { authenticationService } from '../../services/authentication';
import PasswordIcon from '../../assets/images/svg/password.svg';

interface ICodeDeliveryDetails {
    AttributeName: string,
    DeliveryMedium: string,
    Destination: string,
}

interface IProps {
    email: string,
}

function BackIcon({ color }: { color: string }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.97533 4.94165L2.91699 9.99998L7.97533 15.0583" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.0836 10H3.05859" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

function ResetPassword({ email }: IProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const [showPwd, setShowPwd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { contextState } = useContext(BaseDataContext);

    const onValuesChange = (changed: { code: string }) => {
        const { code } = changed;
        if (code && code.length !== 6) setShowPwd(false);
        if (code && code.length === 6) setShowPwd(true);
    };

    const onFinish = async (values: any) => {
        const { code, password } = values;
        setIsLoading(true);
        try {
            const response: any = await authenticationService.resetPassword(code, email, password);
            const { status } = response;
            if (status === 'failed') throw Error(response.message);

            Notification({
                type: 'success',
                message: t('Success_message'),
                description: t('auth_page__password_successfully_changed'),
                duration: 2,
            });
            history.push('/login');
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: 2,
            });
            setIsLoading(false);
        }
    };

    const handleResend = async () => {
        try {
            const response: any = await authenticationService.forgotPassword(email);
            const { httpStatusCode } = response.$metadata;
            if (httpStatusCode !== 200) throw Error(response.message);

            const { CodeDeliveryDetails }: {
                CodeDeliveryDetails: ICodeDeliveryDetails
            } = response;
            Notification({
                type: 'success',
                message: t('Success_message'),
                description: `${t('auth_page__sent_an_email')}: ${CodeDeliveryDetails.Destination} ${t('auth_page__with_secret_code')}`,
                duration: 2,
            });
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: 2,
            });
        }
    };

    return (
        <>
            <div className="login_form--container">
                {
                    !showPwd
                        ? (
                            <>
                                <h2>{t('auth_page__check_email')}</h2>
                                <p>{t('auth_page__to_reset_your_password')}</p>
                            </>
                        )
                        : (
                            <>
                                <Button
                                  type="link"
                                  size="small"
                                  onClick={() => history.push('/login')}
                                  className="go-back--btn"
                                    // eslint-disable-next-line
                                >
                                    <BackIcon color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                                    {t('Back')}
                                </Button>
                                <h2>{t('reset_password')}</h2>
                                <p>{t('auth_page__set_good_password')}</p>
                            </>
                        )
                }

                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  autoComplete="off"
                  className="login-form"
                    // eslint-disable-next-line
                >
                    <Form.Item
                      name="code"
                      rules={[{ required: true, message: t('auth_page__please_input_code') }]}
                      className="custom-input custom-input--password"
                    >
                        <Input.Password
                          prefix={<Icon url={PasswordIcon} />}
                          placeholder="••••••"
                          // eslint-disable-next-line
                          iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor='#0EC7D9' /> : <EyeInvisibleTwoTone twoToneColor='#0EC7D9' />)}
                        />
                    </Form.Item>

                    {
                        showPwd
                            ? (
                                <>
                                    <Form.Item
                                      name="password"
                                      label="Password"
                                      rules={[
                                            {
                                                required: true,
                                                message: t('auth_page__please_input_your_password'),
                                            },
                                        ]}
                                      hasFeedback
                                      className="custom-input custom-input--password"
                                    >
                                        <Input.Password
                                          prefix={<Icon url={PasswordIcon} />}
                                          // eslint-disable-next-line
                                          iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor='#0EC7D9' /> : <EyeInvisibleTwoTone twoToneColor='#0EC7D9' />)}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                      name="confirm"
                                      label={t('auth_page__confirm_password')}
                                      dependencies={['password']}
                                      hasFeedback
                                      rules={[
                                            {
                                                required: true,
                                                message: t('auth_page__please_confirm_password'),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('auth_page__two_passwords_dont_match')));
                                                },
                                            }),
                                        ]}
                                      className="custom-input custom-input--password"
                                    >
                                        <Input.Password
                                          prefix={<Icon url={PasswordIcon} />}
                                          // eslint-disable-next-line
                                          iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor='#0EC7D9' /> : <EyeInvisibleTwoTone twoToneColor='#0EC7D9' />)}
                                        />
                                    </Form.Item>
                                </>
                            )
                            : <span />
                    }

                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {t('auth_page__reset_password')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {
                !showPwd
                    ? (
                        <p className="switch-signup">
                            {t('auth_page__didnt_get_code')}
                            <Button
                              type="link"
                              size="small"
                              onClick={handleResend}
                            >
                                {t('auth_page__resend_code')}
                            </Button>
                        </p>
                    )
                    : ''
            }
        </>
    );
}

export default ResetPassword;
