import React, {
    useState, useEffect, useCallback, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Input, Tooltip } from 'antd';
import { BaseDataContext } from '../../contexts/BaseContext';
import Button from '../Button';
// types
import { inputStatus } from '../../types/common';
// assets
import './Salesforce.scss';
import Screenshot from './Screenshot';
import Help from '../Shopify/Help';

interface SalesForceData {
    client_id: string,
    is_sandbox: boolean,
    start_date: string,
    client_secret: string,
    refresh_token: string,
    auth_type: string,
}

interface ISalesForceProps {
    initial?: SalesForceData,
    alias?: string,
    id?: string,
    subscriptionId?: string,
}

function Salesforce({
    initial, alias, id, subscriptionId,
}: ISalesForceProps) {
    const { t } = useTranslation();
    const { contextState } = useContext(BaseDataContext);
    const { backup } = contextState;

    const [instance, setInstance] = useState('');
    const [valid, setValid] = useState<inputStatus>();

    const handleChangeState = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInstance(e.target.value);
    }, [setInstance]);

    const tooltipText = useMemo(() => ({
        instance: <div className="tooltip-text">
            <span>
                You may locate your instance name by going to your
                Salesforce account and clicking on the “View profile” icon.
            </span>
            <br />
            <i>
                <b>instancename.my.</b>
                salesforce.com
            </i>
            <br />
            <span>
                Select the highlighted part of URL, copy and paste it
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                into "The instance of your Salesforce store" field.
            </span>
            {/* eslint-disable-next-line */}
        </div>,
    }), [t]);

    useEffect(() => {
        // If it's resubscribing mode, then checking
        // valid or not, isn't necessary
        if (alias) return;

        const salesforceService = backup.subscriptions.filter((item) => (item.service === 'salesforce'));

        if (salesforceService.length) {
            setValid('error');
            return;
        }
        setValid('success');
    }, [alias, backup.subscriptions]);

    useEffect(() => {
        if (alias) {
            setInstance(alias);
        }
    }, [alias]);

    const handleClickSignIn = () => {
        // saving instance name to use it after redirect
        window.localStorage.setItem('instance', instance);
        let host = `${window.location.origin}/signin/salesforce`;
        const { REACT_APP_SALESFORCE_CLIENT_ID } = process.env;
        // change redirect url if it's resubscribe flow
        if (id && subscriptionId) {
            const salesforceService = backup.subscriptions.filter((item) => (
                item.id === id
            ));
            if (!salesforceService.length) return;
            host = `${window.location.origin}/resubscribe/salesforce`;
            localStorage.setItem('serviceID', subscriptionId);
        }
        window.location.replace(`https://${instance}.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${host}`);
    };

    return (
        <>
            <div className="selected-service-header">
                <p className="definition mb-0">
                    {t('salesforce_component__instance_name_of_store')}
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span>"https://instancename.my.salesforce.com"</span>
                    {' '}
                    {t('salesforce_component__instance_name_of_store_2')}
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span>"instancename.my".</span>
                </p>
                <Screenshot color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} className="definition-image" />
                <p className="definition">
                    {t('salesforce_component__instance_name_of_store_3')}
                </p>
                <p className="definition">
                    {t('salesforce_component__instance_name_of_store_4')}
                </p>
                <h5>{t('salesforce_component__instance_of_store')}</h5>
            </div>
            <div className="selected-service-body">
                <Input
                  name="instance"
                  value={instance}
                  disabled={!!initial}
                  onChange={handleChangeState}
                  placeholder={`${t('salesforce_component__type_instance')} ...`}
                  className="service-name--input"
                  suffix={(
                      <Tooltip
                        title={tooltipText.instance}
                        overlayClassName={`${contextState.theme === 'light' ? 'light' : 'dark'} input-tooltip-style`}
                        placement="right"
                      >
                          <span>
                              <Help color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} className="" />
                          </span>
                      </Tooltip>
                    )}
                />
                {
                    valid === 'error' && (
                        <p>{t('multiple_instance')} Salesforce.</p>
                    )
                }
            </div>

            <div className="select-service-footer">
                <Button
                  onClick={handleClickSignIn}
                  className="create-btn"
                  disabled={instance === ''}
                >
                    {t('Sign_in_via')} Salesforce
                </Button>
            </div>
        </>
    );
}

export default Salesforce;

Salesforce.defaultProps = {
    initial: undefined,
    alias: undefined,
    id: undefined,
    subscriptionId: undefined,
};
