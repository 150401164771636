import moment from 'moment';
import { IFilter } from '../pages/DataRestore/RestoreFile/types';

type Config = {
    path: string,
    expires?: Date | string,
    [index: string]: any
}

function setCookie(name: string, value: string, options = {}) {
    const configs: Config = {
        path: '/',
        ...options,
    };

    if (configs.expires instanceof Date) {
        configs.expires = configs.expires.toUTCString();
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    Object.keys(configs).forEach((key: string) => {
        updatedCookie += `; ${key}`;
        const optionValue = configs[key];
        if (optionValue !== true) {
            updatedCookie += `=${optionValue}`;
        }
    });

    document.cookie = updatedCookie;
}

// Пример использования:
// setCookie('user', 'John', {secure: true, 'max-age': 3600});

function getCookie(name: string) {
    const matches = document.cookie.match(new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name: string) {
    setCookie(name, '', {
        'max-age': -1,
    });
}

function parseJwt(token: string|null) {
    if (token === null) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
}

function valueDefined(value: any) {
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    return ((value !== undefined) && (value !== '') && (value !== null));
}
// eslint-disable-next-line
function flattenObject(data: object) {
    return Object.assign(
        {},
        // @ts-ignore
        ...(function flatten(o) {
            return [].concat(...Object.keys(o)
                // @ts-ignore
                .map((k) => (typeof o[k] === 'object' && o[k] !== null
                    // @ts-ignore
                    ? flatten(o[k])
                    // @ts-ignore
                    : ({ [k]: o[k] }))));
        }(data)),
    );
}

function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function generateString() {
    let result = '';
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const sortByDate = (a: string, b: string) => {
    let dateA = moment('1970-01-01', 'YYYY-MM-DD');
    let dateB = moment('1970-01-01', 'YYYY-MM-DD');
    if (a !== null) {
        dateA = moment(a, 'YYYY-MM-DD_HH-mm-ss-SSSSSS');
    }
    if (b !== null) {
        dateB = moment(b, 'YYYY-MM-DD_HH-mm-ss-SSSSSS');
    }

    // eslint-disable-next-line no-nested-ternary
    return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

function capitalizeFirstLetter(str: string) {
    if (str === 'quickbooks') return 'QuickBooks';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function compareFilter(oldFilter: IFilter, newFilter: IFilter) {
    if (oldFilter.search !== newFilter.search) return false;
    if (oldFilter.date.dateFrom !== newFilter.date.dateFrom) return false;
    if (oldFilter.date.dateTo !== newFilter.date.dateTo) return false;
    if (oldFilter.isDeep !== newFilter.isDeep) return false;

    return true;
}

export {
    setCookie, getCookie, deleteCookie, parseJwt, valueDefined, flattenObject, formatBytes,
    generateString, sortByDate, capitalizeFirstLetter, compareFilter,
};
