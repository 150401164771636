import React, { useMemo, useEffect, useContext } from 'react';
// utils
// eslint-disable-next-line import/no-unresolved
import { Location } from 'history';
import {
    BrowserView, MobileView,
} from 'react-device-detect';
import { useHistory } from 'react-router-dom';
// Components
import {
    Row,
    Col,
} from 'antd';
import SignIn from '../../components/SignIn';
import SignUp from '../../components/SignUp';
import ForgotPassword from '../../components/ForgotPassword';
import ConfirmSignup from '../../components/ConfirmSignup';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
import { getCookie } from '../../helpers/util';
// Assets
import './Auth.scss';

function Auth({ location }: { location: Location }) {
    const token = getCookie('token');
    const history = useHistory();

    const { contextState } = useContext(BaseDataContext);

    useEffect(() => {
        // If user is already logged in, then redirect to backups page
        if (contextState?.user?.loggedIn && token) {
            history.push('/');
        }
    });

    const Handler = useMemo(() => {
        const { pathname } = location;

        if (pathname === '/signup') return SignUp;
        if (pathname === '/forgot-password') return ForgotPassword;
        if (pathname === '/confirm-signup') return ConfirmSignup;

        return SignIn;
    }, [location.pathname]);

    return (
        <Row>
            <BrowserView className="desktop-view--wrapper">
                <Col span={24} className="login_container">
                    <Handler />
                </Col>
            </BrowserView>

            <MobileView className="login_container--mobile">
                <Handler />
            </MobileView>
        </Row>
    );
}

export default Auth;
