import React from 'react';

interface IProps {
    url: string,
    alt?: string,
    width?: number,
}

function Icon({
    url,
    alt,
    width,
}: IProps) {
    return (
        <img
          src={url}
          alt={alt}
          width={width}
        />
    );
}

export default Icon;

Icon.defaultProps = {
    alt: 'icon',
    width: undefined,
};
