import React, {
    useState, useEffect, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from '../Button';
// types
import { inputStatus } from '../../types/common';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
// assets
import './Hubspot.scss';

interface IAuth20Configs {
    credentials_title: string,
    client_id: string,
    client_secret: string,
    refresh_token: string,
}

interface IHubspotData {
    credentials: IAuth20Configs,
    start_date: string,
}

interface IHubspotProps {
    id?: string,
    subscriptionId?: string;
    initial?: IHubspotData
}

function Hubspot({ id, subscriptionId, initial }: IHubspotProps) {
    const { contextState } = useContext(BaseDataContext);
    const { backup } = contextState;
    const { t } = useTranslation();

    const [valid, setValid] = useState<inputStatus>();
    const [state, setState] = useState<IHubspotData>({
        start_date: '1983-01-01T00:00:00Z',
        credentials: {
            credentials_title: '',
            client_id: '',
            client_secret: '',
            refresh_token: '',
        },
    });

    useEffect(() => {
        const hubspotService = backup.subscriptions.filter((item) => (
            item.service === 'hubspot' && item?.serviceId === state?.credentials?.client_id
        ));

        if (hubspotService.length) {
            setValid('error');
            return;
        }
        setValid('success');
    }, []);

    useEffect(() => {
        if (!initial) return;
        setState(initial);
    }, [initial]);

    const handleClickSignIn = () => {
        let host = `${window.location.origin}/signin/hubspot`;
        // change redirect url if it's resubscribe flow
        if (id && subscriptionId) {
            const hubspotService = backup.subscriptions.filter((item) => (
                item.id === id
            ));
            if (!hubspotService.length) return;
            host = `${window.location.origin}/resubscribe/hubspot`;
            localStorage.setItem('serviceName', hubspotService[0].serviceIdAlias);
            localStorage.setItem('serviceID', subscriptionId);
        }
        const { REACT_APP_HUBSPOT_CLIENT_ID, REACT_APP_HUBSPOT_SCOPES } = process.env;
        window.location.replace(`https://app.hubspot.com/oauth/authorize?client_id=${REACT_APP_HUBSPOT_CLIENT_ID}&scope=${REACT_APP_HUBSPOT_SCOPES}&redirect_uri=${host}`);
    };

    return (
        <>
            <div className="selected-service-body">
                {
                    valid === 'error' && (
                        <p>{t('multiple_instance')} Hubspot.</p>
                    )
                }
                <p className="definition">{t('hubspot_component_description')}</p>
            </div>
            <div className="select-service-footer">
                <Button
                  onClick={handleClickSignIn}
                  className="create-btn"
                  // eslint-disable-next-line
                >
                    {t('Sign_in_via')} Hubspot
                </Button>
            </div>
        </>
    );
}

export default Hubspot;

Hubspot.defaultProps = {
    id: undefined,
    subscriptionId: undefined,
    initial: undefined,
};
