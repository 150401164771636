import React, {
    useState, useMemo, useCallback, useContext,
} from 'react';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
// components
import EnterEmail from './EnterEmail';
import ResetPassword from './ResetPassword';
import Icon from '../Icon';
// utils
import { valueDefined } from '../../helpers/util';
// assets
import LogoIcon from '../../assets/images/logo.png';
import LogoWhiteIcon from '../../assets/images/logo_white.png';

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const { contextState } = useContext(BaseDataContext);

    const Handler = useMemo(() => {
        if (!valueDefined(email)) return EnterEmail;
        return ResetPassword;
    }, [email]);

    const onChange = useCallback((value) => {
        setEmail(value);
    }, []);

    return (
        <div className="auth-modal--wrapper">
            <Icon url={contextState.theme === 'light' ? LogoWhiteIcon : LogoIcon} alt="Fatbrain Logo" width={200} />
            <div>
                <Handler onChange={onChange} email={email} />
            </div>
        </div>
    );
}

export default ForgotPassword;
