import React, { ReactElement, createContext, useState } from 'react';
// types
import { IState } from '../pages/Home/types';
import { getCookie } from '../helpers/util';

type themeType = 'light' | 'dark';

export interface IContextState {
    user: {
        loggedIn: boolean,
        email: string | undefined | null,
    },
    backup: IState,
    theme: themeType,
}

export type BaseContextType = {
    contextState: IContextState,
    // eslint-disable-next-line no-unused-vars
    setContextState: (p: IContextState) => void,
}

const token: string | undefined = getCookie('token');
const theme: themeType = localStorage.getItem('theme') as themeType;

const BaseDataContext = createContext<BaseContextType>({
    contextState: {
        user: {
            loggedIn: !!token,
            email: undefined,
        },
        backup: {
            hasKeys: true,
            subscriptions: [],
        },
        theme: theme || 'dark',
    },
    setContextState: () => {},
});

function BaseDataContextProvider({ children }: { children: ReactElement }) {
    const [contextState, setContextState] = useState<IContextState>({
        user: {
            loggedIn: !!token,
            email: undefined,
        },
        backup: {
            hasKeys: true,
            subscriptions: [],
        },
        theme: theme || 'dark',
    });

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <BaseDataContext.Provider value={{ contextState, setContextState }}>
            {children}
        </BaseDataContext.Provider>
    );
}

export { BaseDataContext, BaseDataContextProvider };
