import { getCookie } from '../helpers/util';

export const servicesService = {
    get,
    check,
    subscribe,
    unsubscribe,
    getInfo,
    getServiceById,
    retestService,
    resubscribe,
    remove,
};

function get() {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/services`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            const { hasKeys, subscriptions } = response;
            const filtered = subscriptions.filter((item: null | object) => item !== null);
            return { hasKeys, subscriptions: filtered };
        });
}

// ========== Deprecated ==========
function check(id: string, data: object) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}
        /api/source-definition/check?sourceDefinitionId=${id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie('token')}`,
            },
            body: JSON.stringify(data),
        },
    )
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function subscribe(code: string, data: object) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/services/subscribe?serviceCode=${code}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie('token')}`,
            },
            body: JSON.stringify(data),
        },
    )
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function unsubscribe(id: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/services/unsubscribe?subscriptionId=${id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie('token')}`,
            },
        },
    )
        .then((response: Response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

// ========== Deprecated ==========
function getInfo(code: string) {
    // eslint-disable-next-line no-undef
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('token')}`,
    };

    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/docs?serviceCode=${code}`, headers)
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.text();
        });
}

async function getServiceById(subscriptionId: string) {
    try {
        return await fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/source/get`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${getCookie('token')}`,
            },
            body: JSON.stringify({ subscriptionId }),
        })
            .then((response) => {
                if (response.status === 401) {
                    throw Error('401');
                }
                return response.json();
            })
            .then((response) => {
                if (response.status === 'failed') {
                    throw Error(response?.errorCode || response?.message);
                }

                return response;
            });
    } catch (e) {
        return e;
    }
}

function retestService(data: object) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/source/check_for_update`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getCookie('token')}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}

function resubscribe(data: object) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/services/resubscribe`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getCookie('token')}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }
            return response;
        });
}

function remove(subscriptionId: string) {
    return fetch(`${process.env.REACT_APP_SERVERLESS_URL}/api/services/remove`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getCookie('token')}`,
        },
        body: JSON.stringify({ subscriptionId }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw Error('401');
            }
            return response.json();
        })
        .then((response) => {
            if (response.status === 'failed') {
                throw Error(response?.errorCode || response?.message);
            }

            return response;
        });
}
