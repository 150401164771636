import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseDataContext } from '../../contexts/BaseContext';
import Notification from '../Notification';
import Button from '../Button';
import Icon from '../Icon';
// utils
import { authenticationService } from '../../services/authentication';
// assets
import SmsIcon from '../../assets/images/svg/sms.svg';

interface ICodeDeliveryDetails {
    AttributeName: string,
    DeliveryMedium: string,
    Destination: string,
}

interface IProps {
    onChange: Function,
}

function BackIcon({ color }: { color: string }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.97533 4.94165L2.91699 9.99998L7.97533 15.0583" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.0836 10H3.05859" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

function EnterEmail({ onChange }: IProps) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const { contextState } = useContext(BaseDataContext);

    const onFinish = async (values: any) => {
        const { email } = values;
        setIsLoading(true);
        try {
            const response: any = await authenticationService.forgotPassword(email);
            const { status } = response;
            if (status === 'failed') throw Error(response.message);

            const { CodeDeliveryDetails }: {
                CodeDeliveryDetails: ICodeDeliveryDetails
            } = response;
            Notification({
                type: 'success',
                message: t('Success_message'),
                description: `${t('auth_page__sent_an_email')}: ${CodeDeliveryDetails.Destination} ${t('auth_page__with_secret_code')}`,
                duration: null,
            });
            onChange(email);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: null,
            });
            setIsLoading(false);
        }
    };

    return (
        <div className="login_form--container">
            <Button
              type="link"
              size="small"
              onClick={() => history.push('/login')}
              className="go-back--btn"
              // eslint-disable-next-line
            >
                <BackIcon color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                {t('Back')}
            </Button>
            <h2>{t('auth_page__forgot_password')}?</h2>
            <p>{t('auth_page__please_enter_your_registered_email')}
            </p>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              className="login-form"
              // eslint-disable-next-line
            >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: t('auth_page__please_input_your_email') }]}
                  className="custom-input custom-input--text"
                >
                    <Input prefix={<Icon url={SmsIcon} />} placeholder={t('auth_page__enter_your_email')} />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        {t('auth_page__reset_password')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default EnterEmail;
