import React, { forwardRef, LegacyRef } from 'react';

const HubspotLogotype = forwardRef((props: { color: string }, ref: LegacyRef<SVGSVGElement>) => {
    const { color } = props;
    return (
        <svg ref={ref} xmlns="http://www.w3.org/2000/svg" height="800" width="1200" viewBox="-15.9 -7.5145 137.8 45.087">
            <g fill="none">
                <path fill={color} d="M11.48 4.976v8.475H3.694V4.976H0v20.231h3.695v-8.2h7.784v8.2h3.696V4.976zm15.522 13.692a3.0575 3.0575 0 11-6.115 0v-8.666h-3.505v8.666a6.556 6.556 0 0013.112 0v-8.666h-3.492zm25.971-7.773c0-1.777 1.175-2.34 2.462-2.34 1.038 0 2.408.79 3.305 1.748l2.295-2.706c-1.146-1.55-3.47-2.621-5.373-2.621-3.803 0-6.553 2.227-6.553 5.919 0 6.85 8.372 4.676 8.372 8.51 0 1.182-1.148 2.226-2.462 2.226-2.073 0-2.745-1.013-3.697-2.085l-2.548 2.649c1.63 2 3.64 3.016 6.047 3.016 3.613 0 6.519-2.254 6.519-5.778 0-7.604-8.373-5.241-8.373-8.538m51.8 11.08c-2.071 0-2.66-.896-2.66-2.268v-6.075h3.22v-3.078h-3.22V6.493l-3.554 1.595v12.374c0 3.165 2.184 4.76 5.178 4.76.47.008.94-.03 1.402-.112l.867-3.192c-.391.027-.84.054-1.233.054M40.344 10.103c-1.736 0-2.948.504-4.12 1.653V5.097h-3.51V17.44c0 4.62 3.34 7.786 7.094 7.786 4.164 0 7.827-3.222 7.827-7.56 0-4.284-3.371-7.56-7.291-7.56m-.022 11.587a3.9835 3.9835 0 110-7.967 3.9835 3.9835 0 010 7.967m38.142-4.211c0-4.346-3.655-7.56-7.827-7.56-3.753 0-7.094 3.165-7.094 7.786v12.35h3.51V23.39c1.17 1.147 2.384 1.653 4.118 1.653 3.92 0 7.292-3.276 7.292-7.561m-3.323-.044a3.9835 3.9835 0 11-7.967 0 3.9835 3.9835 0 017.967 0" />
                <path fill="#FF7A59" d="M89.806 9.752V6.225a2.716 2.716 0 001.566-2.448v-.081c0-1.5-1.216-2.716-2.716-2.716h-.081a2.716 2.716 0 00-2.716 2.716v.081a2.716 2.716 0 001.566 2.448v3.527a7.691 7.691 0 00-3.657 1.61l-9.673-7.534c.069-.249.105-.505.109-.762A3.06 3.06 0 1071.14 6.12a3.027 3.027 0 001.507-.41l9.525 7.412a7.715 7.715 0 00.118 8.69l-2.897 2.898a2.488 2.488 0 00-.724-.118 2.513 2.513 0 102.515 2.515 2.48 2.48 0 00-.118-.724l2.866-2.867a7.728 7.728 0 105.874-13.764m-1.188 11.6a3.965 3.965 0 110-7.927 3.965 3.965 0 01.004 7.925" />
            </g>
        </svg>
    );
});

export default HubspotLogotype;
