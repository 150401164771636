import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon';
import ErrorIcon from '../../../assets/images/svg/error-2.svg';
import TickIcon from '../../../assets/images/svg/tick.svg';

interface IProps {
    id: string,
    eventType: string,
    isError: string,
    createdAt: string,
}

function AuditOfActionCard(data: IProps) {
    const {
        id, eventType, isError, createdAt,
    } = data;
    const { t } = useTranslation();
    return (
        <div className="audit_of_actions-card--wrapper" key={id}>
            <div>
                <span>{ eventType?.split('_').join(' ') }</span>
            </div>
            <div>
                <span className={`status-component ${isError === 'failed' ? 'fail' : ''}`}>
                    <Icon url={isError === 'failed' ? ErrorIcon : TickIcon} width={24} />
                    { isError === 'failed' ? t('Fail') : t('Success') }
                </span>
                <span className="file-name-wrapper">{ moment(new Date(createdAt)).format('YYYY-MM-DD HH:mm:ss') }</span>
            </div>
        </div>
    );
}

export default AuditOfActionCard;
