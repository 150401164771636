import React, {
    useState, useMemo, useEffect, useCallback, useRef,
} from 'react';
// utils
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// components
import RestoreFilter, { initialFilter } from '../../../components/RestoreFilter';
import Table from '../../../components/Table';
import Icon from '../../../components/Icon';
import Notification from '../../../components/Notification';
import Button from '../../../components/Button';
import RestoreFileCard from '../RestoreFileCard';
import Pagination from '../../../components/Pagination';
// utils
import { restoreService } from '../../../services';
import { formatBytes, capitalizeFirstLetter, compareFilter } from '../../../helpers/util';
import { icons } from './data';
// types
import {
    ICustomLocation, IFile, IFilter, zipSubfoldersProps,
} from './types';
// assets
import DownloadIcon from '../../../assets/images/svg/download-2.svg';
import BackIcon from '../../../assets/images/svg/arrow-left.svg';
import './RestoreFile.scss';

function RestoreFile({ location }: { location: ICustomLocation }) {
    const { t } = useTranslation();
    const history = useHistory();

    const isMounted = useRef(true);
    const filterRef = useRef<IFilter>(initialFilter);

    const [files, setFiles] = useState<Array<IFile>>([]);
    const [pagination, setPagination] = useState({
        page: 1,
        total: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSelect, setIsSelect] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const handleActivateSelect = useCallback(() => {
        setIsSelect(true);
    }, []);

    const handleDownload = async (record: IFile) => {
        if (!location?.service) return;
        const { subscriptionId, service } = location.service;
        if (!record || !subscriptionId) return;

        const date = moment(new Date(record.jobDate)).format('YYYY-MM-DD');
        const serviceFormatted = capitalizeFirstLetter(service);
        const file = record.folder.split('_').map((s) => capitalizeFirstLetter(s)).join('');
        const fileName = `${date}_${serviceFormatted}_${file}.csv`;

        setIsLoading(true);
        await restoreService.getSubfolderFile(subscriptionId, record.storageKey, fileName)
            .then(() => {
                if (!isMounted.current) return;
                Notification({
                    type: 'success',
                    message: t('data_restore_page__successfully_downloaded'),
                    description: `${t('File')} ${fileName} ${'data_restore_page__downloaded'}.`,
                    duration: null,
                });
            })
            .catch((error) => {
                if (error.message === '401') {
                    history.push('/login');
                }
                if (!isMounted.current) return;
                Notification({
                    type: 'error',
                    message: t('data_restore_page__downloaded_error'),
                    description: t(error.message),
                    duration: null,
                });
            })
            .finally(() => {
                if (!isMounted.current) return;
                setIsLoading(false);
            });
    };

    const handleDownloadSelection = () => {
        const data = {
            keys: selectedRowKeys,
            dateFrom: null,
            dateTo: null,
            mode: null,
            search: null,
        };
        download(data);
    };

    const handleDownloadAll = () => {
        const { date, search, isDeep } = filterRef.current;
        const { dateFrom, dateTo } = date;
        const data = {
            keys: null,
            dateFrom,
            dateTo,
            mode: isDeep ? 'fullsearch' : null,
            search,
        };
        download(data);
    };

    const handleFilter = useCallback((f: IFilter) => {
        setPagination({
            page: 1,
            total: 0,
        });

        if (compareFilter(filterRef.current, f)) return;
        filterRef.current = f;

        getData(f);
    }, []);

    const handleChangePage = useCallback((nextPage: number) => {
        setPagination((prev) => ({
            ...prev,
            page: nextPage,
        }));
    }, []);

    const handleSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    }, []);

    const columns = useMemo(() => ([
        {
            title: t('data_restore_page__folder_name'),
            dataIndex: 'folder',
            key: 'folder',
            render: (folderName: string) => (
                <span className="file-name-wrapper">{ folderName.split('_').join(' ') }</span>
            ),
        },
        {
            title: t('data_restore_page__backup_date'),
            dataIndex: 'jobDate',
            key: 'jobDate',
            render: (date: string) => {
                const newDate = moment(date).format('MM/DD/YYYY');
                return (
                    <span className="file-name-wrapper">{ newDate }</span>
                );
            },
        },
        {
            title: t('Size'),
            dataIndex: 'size',
            key: 'size',
            render: (size: number) => (
                <span className="file-name-wrapper">{ formatBytes(size) }</span>
            ),
        },
        {
            title: '',
            key: 'action',
            width: 50,
            render: (_: any, record: IFile) => (
                <div
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => handleDownload(record)}
                  onClick={() => handleDownload(record)}
                  className="restore-file-actions-wrapper"
                    // eslint-disable-next-line
                >
                    <Icon url={DownloadIcon} width={24} />
                </div>
            ),
        },
    ]), [t]);

    useEffect(() => {
        if (!location?.service) {
            history.push({ pathname: '/' });
            return;
        }

        getData(filterRef.current);
    }, [location?.service, pagination.page]);

    useEffect(() => () => {
        isMounted.current = false;
    }, []);

    const isMobile = window.innerWidth <= 480;

    const getData = (filter: IFilter) => {
        const subscriptionId = location?.service?.subscriptionId;

        setIsLoading(true);
        const { search, date, isDeep } = filter;
        const { dateFrom, dateTo } = date;
        const data = {
            subscriptionId,
            page: pagination.page - 1,
            dateFrom,
            dateTo,
            mode: isDeep ? 'fullsearch' : null,
            search: search === null ? null : search.toLocaleLowerCase().split(' ').join('_'),
        };

        restoreService.getSubfolders(data)
            .then((response) => {
                if (!isMounted.current) return;
                if (response.status === 'failed') throw Error(response.message);

                const { content, total } = response;
                setFiles(content);
                setPagination((prev) => ({
                    ...prev,
                    total,
                }));
            })
            .catch((error) => {
                if (error.message === '401') {
                    history.push('/login');
                }
                if (!isMounted.current) return;
                Notification({
                    type: 'error',
                    message: t('data_restore_page__fetch_files_failed'),
                    description: t(error.message),
                    duration: null,
                });
            })
            .finally(() => {
                if (!isMounted.current) return;
                setIsLoading(false);
            });
    };

    const download = (props: zipSubfoldersProps) => {
        if (!location?.service) return;
        const { subscriptionId } = location.service;
        if (!subscriptionId) return;

        const payload = {
            subscriptionId,
            ...props,
        };

        setIsLoading(true);
        // @ts-ignore
        restoreService.zipSubfolders(payload)
            .then(() => {
                if (!isMounted.current) return;
                Notification({
                    type: 'success',
                    message: t('data_restore_page__archiving'),
                    description: t('data_restore_page__archiving_text'),
                    duration: null,
                });
            })
            .catch((error) => {
                if (error.message === '401') {
                    history.push('/login');
                }
                if (!isMounted.current) return;
                Notification({
                    type: 'error',
                    message: t('data_restore_page__archiving_error'),
                    description: t(error.message),
                    duration: null,
                });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <section className="services-page--header create-service">
                <Button
                  type="link"
                  onClick={() => history.push('/')}
                >
                    <Icon url={BackIcon} width={24} />
                </Button>
                <h2>{t('data_restore_page__download_backup')}</h2>
            </section>
            <section className="restore-file--container">
                {/* TODO: Do we really need this alert??? */}
                {/* <Alert */}
                {/*  className="alert-container" */}
                {/*  description={t('data_restore_page__description_key_emailed')} */}
                {/*  type="success" */}
                {/*  closable */}
                {/*  closeIcon={<Icon url={CloseIcon} />} */}
                {/* /> */}
                <div className="restore-file--title">
                    <div>
                        <Icon url={icons[location?.service?.service]} width={32} />
                    </div>
                    <h5>{ location?.service?.service || '' }</h5>
                </div>
                <Button
                  type="link"
                  className="restore-file-page--btn"
                  onClick={() => history.push('/downloads')}
                  // eslint-disable-next-line
                >
                    {t('home_page__downloads')}
                </Button>
                <RestoreFilter onSubmit={handleFilter} />
                <div className="selection-actions--wrapper">
                    {!isSelect ? (
                        <Button
                          type="link"
                          className="selection-restoreFile--btn"
                          onClick={handleActivateSelect}
                        >
                            {t('Select')}
                        </Button>
                    ) : (
                        <>
                            <div>
                                <span className="selection-counter--text">Selected: {selectedRowKeys.length}</span>
                                <Button
                                  type="link"
                                  className="selection-restoreFile--btn"
                                  onClick={() => setSelectedRowKeys([])}
                                  disabled={!selectedRowKeys.length}
                                >
                                    {t('Cancel Selection')}
                                </Button>
                                <Button
                                  type="link"
                                  className="selection-restoreFile--btn"
                                  onClick={handleDownloadAll}
                                >
                                    {t('Download All')}
                                </Button>
                            </div>
                            <Button
                              type="link"
                              className="selection-restoreFile--btn"
                              onClick={handleDownloadSelection}
                              disabled={!selectedRowKeys.length}
                            >
                                Download Selected
                            </Button>
                        </>
                    )}
                </div>
                {
                    isMobile
                        ? (
                            <RestoreFileCard
                              items={files}
                              isSelect={isSelect}
                              onSelect={handleSelectChange}
                              onDownload={handleDownload}
                              selectedRowKeys={selectedRowKeys}
                            />
                        )
                        : (
                            <Table
                              rowKey="storageKey"
                              columns={columns}
                              data={files}
                              loading={isLoading}
                              bordered={false}
                              className="restore-file--table"
                              rowSelection={isSelect ? {
                                  preserveSelectedRowKeys: true,
                                  selectedRowKeys,
                                  onChange: handleSelectChange,
                              } : undefined}
                            />
                        )
                }
                <Pagination
                  page={pagination.page}
                  total={pagination.total}
                  onPageChange={handleChangePage}
                />
            </section>
        </>
    );
}

export default RestoreFile;
