import React, {
    FC, useState, useCallback, useEffect, useContext,
} from 'react';
// utils
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// components
import { Pagination, Spin } from 'antd';
import Button from '../../components/Button';
import { BackwardIcon, ForwardIcon } from '../AuditOfActions';
import Notification from '../../components/Notification';
import DownloadsCard from './DownloadsCard';
import { BackIcon } from '../Service/Service';
// helpers
import { BaseDataContext, IContextState } from '../../contexts/BaseContext';
import { downloadsService, servicesService } from '../../services';
import { deleteCookie } from '../../helpers/util';
// types
import { IDownloadsFile } from './types';
import { IState } from '../Home/types';
// assets
import './Downloads.scss';

const Downloads: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { contextState, setContextState } = useContext(BaseDataContext);
    const { subscriptions } = contextState.backup;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<IDownloadsFile>>([]);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 0,
    });

    const handleChangePage = useCallback((page) => {
        getZips(page);
    }, [subscriptions]);

    const itemRender = useCallback((_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <div className="prev-btn--container">
                    <BackwardIcon color={pagination.current === 1 ? '#B3B8C7' : '#0EC7D9'} />
                    <Button type="link" disabled={pagination.current === 1}>{t('Previous')}</Button>
                </div>
            );
        }
        if (type === 'next') {
            const totalPages = Math.ceil(pagination.total / 10);
            return (
                <div className="prev-btn--container">
                    <Button type="link" disabled={pagination.current === totalPages}>{t('Next')}</Button>
                    <ForwardIcon color={pagination.current === totalPages ? '#B3B8C7' : '#0EC7D9'} />
                </div>
            );
        }
        return originalElement;
    }, [pagination.current]);

    const handleDownload = async (file: IDownloadsFile) => {
        await downloadsService.download(file.subscriptionId, file.zipKey)
            .then(() => {
                Notification({
                    type: 'success',
                    message: t('data_restore_page__successfully_downloaded'),
                    description: `${t('File')} ${file.zipKey} ${t('data_restore_page__downloaded')}.`,
                    duration: null,
                });
            })
            .catch((error) => {
                if (error.message === '401') {
                    history.push('/login');
                }
                Notification({
                    type: 'error',
                    message: t('data_restore_page__downloaded_error'),
                    description: t(error.message),
                    duration: null,
                });
            });
        return true;
    };

    const handleRefresh = async (file: IDownloadsFile) => {
        await downloadsService.refresh(file.id)
            .then((response: IDownloadsFile) => {
                const newData = data.map((item) => {
                    if (item.id === response.id) return response;
                    return item;
                });
                setData(newData);
            })
            .catch((error) => {
                if (error.message === '401') {
                    history.push('/login');
                }
                Notification({
                    type: 'error',
                    message: t('data_restore_page__downloaded_error'),
                    description: t(error.message),
                    duration: null,
                });
            });
        return true;
    };

    useEffect(() => {
        getServicesList();
    }, []);

    useEffect(() => {
        getZips(1);
    }, [contextState.backup.subscriptions]);

    async function getZips(page: number): Promise<void> {
        if (!subscriptions.length) return;
        try {
            setIsLoading(true);
            const { content, total } = await downloadsService.get({ page: page - 1 });
            setData(content);
            setPagination({
                total,
                current: page,
            });
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function getServicesList() {
        setIsLoading(true);
        servicesService.get()
            .then((response: IState) => {
                // @ts-ignore
                setContextState((p: IContextState) => ({
                    ...p,
                    backup: response,
                }));
            })
            .catch((e: any) => {
                if (e.message === '401') {
                    deleteCookie('token');
                    history.push('/login');
                }
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <section className="services-page--header create-service">
                <Button
                  type="link"
                  onClick={() => history.push('/')}
                >
                    <BackIcon color={contextState.theme === 'light' ? '#000000' : '#FFFFFF'} />
                </Button>
                <h2>{t('downloads_page__downloads')}</h2>
            </section>
            <section className="downloads-page--container">
                {isLoading ? <Spin /> : (
                    <>
                        {data.map((item) => (
                            <DownloadsCard
                              record={item}
                              onRefresh={handleRefresh}
                              onDownload={handleDownload}
                            />
                        ))}
                        <Pagination
                          itemRender={itemRender}
                          current={pagination.current}
                          total={pagination.total}
                          onChange={handleChangePage}
                          className="custom-pagination"
                          hideOnSinglePage
                          pageSize={10}
                          showSizeChanger={false}
                        />
                    </>
                )}
            </section>
        </>
    );
};

export default Downloads;
